import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton } from '@mui/material';
import { CButton, CModal, CModalHeader, CTooltip, CModalTitle, CModalBody, CModalFooter, CFormCheck, CFormInput, CBadge, CFormSwitch,CAlert } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCheckCircle, cilXCircle } from '@coreui/icons';
import { AssesmentRepository } from "src/repositories/assessmentRepository";
import { styled } from '@mui/material/styles';import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import  { tableCellClasses } from '@mui/material/TableCell';
import  { tablePaginationClasses } from '@mui/material/TablePagination';

const getStatusIcon = status => {
    switch (status?.toLowerCase()) {
        case 'in_progress': return '🟡';  // Yellow circle
        case 'completed': return '🟢';  // Green circle
        default: return '🟡';
    }
}

const Responses = ({ projectId, assessmentId }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [disacardModal, setDisacardModal] = useState(false);
    const [curated, setVisibleCurated] = useState(false);
    const [multiDiscard, setMultiDiscard] = useState(false);
    const [multiCurated, setMultiCurated] = useState(false);
    const [reason, setReason] = useState('');
    const [singleCurated, setSingleCurated] = useState('');
    const [singleDiscard, setSingleDiscard] = useState('');
    const [tableData, setTableData] = useState([]);
    const [projectIdRes, setProjectIdRes] = useState('');
    const [expandedRows, setExpandedRows] = useState({});
    const [viewMoreModal, setViewMoreModal] = useState(false);
    const [viewMore, setViewMore] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setProjectIdRes(projectId);
        getResponseUserDetails();
    }, []);

    const getResponseUserDetails = async () => {
        try {
            const response = await AssesmentRepository.getUserResponsesById(projectId);
            setTableData(response.data);
        } catch (error) {
            console.error('Error retrieving user response details:', error);
        }
    };

    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words?.length <= wordLimit) return text;
        return words.slice(0, wordLimit).join(' ') + '...';
    };

    // Toggle expanded state for a given row
    const toggleExpand = (sequence) => {
        setExpandedRows(prevState => ({
            ...prevState,
            [sequence]: !prevState[sequence]
        }));
    };

    // useEffect(() => {
    //     console.log(selectedRows);
    // }, [selectedRows]);

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    const handleSelectAll = (event) => {
        const allRows = tableData.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
        const allRowsIds = allRows.map((row) => row.session_id);
        if (event.target.checked) {
            setSelectedRows(allRowsIds);
        } else {
            setSelectedRows([]);
        }
    };

    const handleChangeSelectRow = (id, checked) => {
        if (checked) {
            setSelectedRows((prev) => [...prev, id]);
        } else {
            setSelectedRows((prev) => prev.filter((rowId) => rowId !== id));
        }
    };

    const handleSinlge = (id, checked, DiscardOrCurate) => {
        setSelectedRows([]);
        handleChangeSelectRow(id, checked);
        if (DiscardOrCurate && checked) {
            setDisacardModal(true);
        } else if (DiscardOrCurate && !checked) {
            setDisacardModal(false);
        } else if (!DiscardOrCurate && checked) {
            setVisibleCurated(true);
        } else if (!DiscardOrCurate && !checked) {
            setVisibleCurated(false);
        }
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const handleSetCurate = async () => {
        try {
            const body = {
                sessionIds: selectedRows
            }
            const response = await AssesmentRepository.createCurateResponse(assessmentId, body);
            if (response) {
                getResponseUserDetails();
                setMultiCurated(false);
                setVisibleCurated(false);
                setSelectedRows([]);
            }
        } catch (error) {
            console.error('Error setting curate:', error);
        }
    }

    const handleSetDiscard = async (reason) => {
        try {
            const body = {
                sessionIds: selectedRows,
                discardReason: reason
            }
            const response = await AssesmentRepository.createDiscardedResponse(assessmentId, body);
            if (response) {
                getResponseUserDetails();
                setDisacardModal(false);
                setMultiDiscard(false);
                setSelectedRows([]);
                setReason('')
            }
        } catch (error) {
            console.error('Error setting reject:', error);
        }
    }

    const renderedData = tableData?.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
    const questionMapping = {};
    let maxSequence = 0;
    tableData?.forEach(item => {
        item.responses?.forEach(response => {
            const sequenceNo = Number(response.sequence_no);
            questionMapping[sequenceNo] = response.q_text;
            maxSequence = Math.max(maxSequence, sequenceNo);
        });
    });

    const sortedSequenceNumbers = Array.from({ length: maxSequence }, (_, i) => String(i + 1));

    const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '--TextField-brandBorderColor': '#fff',
                        '--TextField-brandBorderHoverColor': '#fff',
                        '--TextField-brandBorderFocusedColor': '#fff',
                        '& label': {
                            color: '#9fffac',
                        },
                        '& label.Mui-focused': {
                            color: '#9fffac',
                        },
                    },
                },
            },

            // MuiFilledInput: {
            //   styleOverrides: {
            //     root: {
            //       '&::before, &::after': {
            //         borderBottom: '2px solid var(--TextField-brandBorderColor)',
            //       },
            //       '&:hover:not(.Mui-disabled, .Mui-error):before': {
            //         borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            //       },
            //       '&.Mui-focused:after': {
            //         borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            //       },
            //     },
            //   },
            // },
            MuiInput: {
                styleOverrides: {
                    root: {
                        '&::before': {
                            borderBottom: '2px solid var(--TextField-brandBorderColor)',
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                            // color:'#fff',
                            backgroundColor: '#252525'
                        },
                        '&.Mui-focused': {
                            backgroundColor: '#252525 !important',
                            caretColor: '#fff !important',
                            boxShadow: '0 0 #00800033 !important',
                            padding: '0 1rem !important',
                            color: ' #fff !important'
                        }
                    },
                },
            },
        },
    });


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#252525",
        border: '1px solid var(--secondary-dark-bg)',
        //   color: theme.palette.common.white,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: "150%",
        color: '#d9d9d9',
        '::hover': {
            color: '#d9d9d9',
        }

    },
    
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        fontStyle: 'normal',
        border: '1px solid var(--secondary-dark-bg)',
        fontWeight: 300,
        fontSize: '16px',
        color: '#d9d9d9',
        backgroundColor: "#252525",
    }

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type': {
        backgroundColor: '#252525',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        // border: 0,
    },
}));

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    '&:MuiTablePagination-root': {
        backgroundColor: '#252525',
        color: '#d9d9d9',
    },
    [`&.${tablePaginationClasses.root}`]: {
        backgroundColor: '#252525',
        color: '#d9d9d9',
    },
   
}));

    return (
        <div>
            {tableData?.length > 0 && (
                <div className='flexgap1' style={{ width: '100%', marginBottom: '1rem', height: '2.5rem', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className='flexgap1'>
                        {selectedRows?.length > 0 && (
                            <>
                                <CButton onClick={() => setMultiCurated(true)}>
                                    Verify ({selectedRows?.length})
                                </CButton>
                                <CButton onClick={() => setMultiDiscard(true)}>
                                    Reject ({selectedRows?.length})
                                </CButton>
                            </>
                        )}
                    </div>
                    <div>
                        <CFormSwitch
                            label='Expand/Collapse'
                            id="collapseExpandSwitch"
                            checked={isExpanded}
                            onChange={() => setIsExpanded(prevState => !prevState)}
                        />
                    </div>
                </div>
            )}
            <Paper>
                {tableData?.length > 0 ? (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell padding="checkbox" style={{ fontWeight: 'bold', minWidth: "10rem" }} >
                                            <div className='flex-evenly' style={{ marginRight: "1.9rem" }}>
                                                <CFormCheck
                                                    id="selectAll"
                                                    indeterminate={selectedRows?.length > 0 && selectedRows?.length < renderedData?.length}
                                                    checked={selectedRows?.length === renderedData?.length && renderedData?.length > 0}
                                                    onChange={handleSelectAll}
                                                    style={{ height: '1.3rem', width: '1.3rem', margin: '0', cursor: 'pointer', paddingRight: '2px' }}
                                                />
                                                <span style={{ paddingLeft: '6px' , color:'white'}}>Select All</span>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell style={{ fontWeight: 'bold', width: "5rem" }}>Status</StyledTableCell>
                                        {sortedSequenceNumbers.map((sequence, index) => (
                                            <StyledTableCell key={sequence} style={{ fontWeight: 'bold' }}>
                                                {`Q${sequence}`}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {renderedData.map((row) => (
                                        <TableRow key={row.session_id}>
                                            <StyledTableCell style={{ minWidth: "10rem", paddingLeft: "0px" }} padding="checkbox">
                                                <div className='flex-evenly'>
                                                    <CFormCheck
                                                        onChange={(event) => handleChangeSelectRow(row.session_id, event.target.checked)}
                                                        style={{ height: '1.3rem', width: '1.3rem', margin: '0', cursor: 'pointer' }}
                                                        checked={selectedRows.includes(row.session_id)}
                                                    />
                                                    <CTooltip placement="top" content="Verify">
                                                        <IconButton onClick={() => { handleSinlge(row.session_id, true, false), setSingleCurated(row.session_id) }} style={{ cursor: 'pointer' }}>
                                                            <CIcon icon={cilCheckCircle}  style={{ color: 'white' }}/>
                                                        </IconButton>
                                                    </CTooltip>
                                                    <CTooltip placement="top" content="Reject">
                                                        <IconButton onClick={() => { handleSinlge(row.session_id, true, true), setSingleDiscard(row.session_id) }} style={{ cursor: 'pointer' }}>
                                                            <CIcon icon={cilXCircle}  style={{ color: 'white' }}/>
                                                        </IconButton>
                                                    </CTooltip>
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell style={{ padding: "4px" }}>
                                                <div className='flexCenter'>
                                                    <CTooltip content={row.status === 'COMPLETED' ? 'Completed' : row.status === 'IN_PROGRESS' ? 'In Progress' : row.status}>
                                                        <CBadge >
                                                            {getStatusIcon(row.status)}
                                                        </CBadge>
                                                    </CTooltip>
                                                </div>
                                            </StyledTableCell>
                                            {/* {sortedSequenceNumbers.map(sequence => (
                                                <TableCell style={{ minWidth: "10rem" }} key={sequence}>
                                                    {row.responses.find(response => String(response.sequence_no) === sequence)?.answer_text || ''}
                                                </TableCell>
                                            ))} */}
                                            {sortedSequenceNumbers.map(sequence => {
                                                const response = row.responses.find(response => String(response.sequence_no) === sequence);
                                                let text = response?.answer_text || '';
                                                let truncatedText = '';


                                                const hasAddress = text.includes('address');
                                                const hasLatitude = text.includes('latitude');
                                                const hasLongitude = text.includes('longitude');

                                                if (hasAddress) {
                                                    const addressMatch = text.match(/"address":"([^"]+)"/);
                                                    text = addressMatch ? addressMatch[1] : text;
                                                    truncatedText = truncateText(text, 5);
                                                } else if (hasLatitude && hasLongitude) {
                                                    const latitudeMatch = text.match(/"latitude":([0-9.-]+)/);
                                                    const longitudeMatch = text.match(/"longitude":([0-9.-]+)/);
                                                    
                                                    if (latitudeMatch && longitudeMatch) {
                                                        text = `Latitude: ${latitudeMatch[1]}, Longitude: ${longitudeMatch[1]}`;
                                                        truncatedText = truncateText(text, 5);
                                                    }
                                                } else {
                                                    truncatedText = truncateText(text, 5);
                                                }

                                                return (
                                                    <StyledTableCell style={{ minWidth: "10rem" }} key={sequence}>
                                                        {isExpanded ? text : truncatedText}
                                                    </StyledTableCell>
                                                );
                                            })}

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StyledTablePagination
                            component="div"
                            count={tableData?.length}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onPageChange={handlePageChange}
                            // onRowsPerPageChange={handleRowsPerPageChange}
                            rowsPerPageOptions={[]}
                        />
                    </>
                ) : (
                    <CAlert color="warning">No responses available to display.</CAlert>
                )}
            </Paper>
            <CModal
                visible={disacardModal}
                onClose={() => handleSinlge(singleDiscard, false, true)}
                aria-labelledby="LiveDemoExampleLabel"
            >
                <CModalHeader onClose={() => { handleSinlge(singleDiscard, false, true); setReason('') }}>
                    <CModalTitle id="LiveDemoExampleLabel">Reject</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>Are you sure you want to reject this response?</p>
                    <CFormInput
                        type="text"
                        placeholder="Enter reason"
                        value={reason}
                        onChange={handleReasonChange}
                        style={{ marginTop: "1rem" }}
                    />
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => { handleSinlge(singleDiscard, false, true); setReason('') }}>
                        Close
                    </CButton>
                    <CButton
                        color="primary"
                        disabled={!reason.trim()}
                        onClick={() => handleSetDiscard(reason)}
                    >
                        Reject
                    </CButton>
                </CModalFooter>
            </CModal>

            <CModal
                visible={curated}
                onClose={() => handleSinlge(singleCurated, false, false)}
                aria-labelledby="CurateModal"
            >
                <CModalHeader onClose={() => handleSinlge(singleCurated, false, false)}>
                    <CModalTitle id="CurateModal">Verify</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>Are you sure you want to mark this response as verified?</p>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => handleSinlge(singleCurated, false, false)}>
                        Close
                    </CButton>
                    <CButton onClick={() => handleSetCurate()} color="primary">Yes</CButton>
                </CModalFooter>
            </CModal>


            <CModal
                visible={multiDiscard}
                onClose={() => setMultiDiscard(false)}
                aria-labelledby="LiveDemoExampleLabel"
            >
                <CModalHeader onClose={() => setMultiDiscard(false)}>
                    <CModalTitle id="LiveDemoExampleLabel">Reject</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>Are you sure you want to reject these {selectedRows?.length} responses?</p>
                    <CFormInput
                        type="text"
                        placeholder="Enter reason"
                        value={reason}
                        onChange={handleReasonChange}
                        style={{ marginTop: "1rem" }}
                    />
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setMultiDiscard(false)}>
                        Close
                    </CButton>
                    <CButton
                        color="primary"
                        disabled={!reason.trim()}
                        onClick={() => handleSetDiscard(reason)}
                    >
                        Reject
                    </CButton>
                </CModalFooter>
            </CModal>

            <CModal
                visible={multiCurated}
                onClose={() => setMultiCurated(false)}
                aria-labelledby="CurateModal"
            >
                <CModalHeader onClose={() => setMultiCurated(false)}>
                    <CModalTitle id="CurateModal">Verify</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <p>Are you sure you want to mark these {selectedRows?.length} responses as verified?</p>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => setMultiCurated(false)}>
                        Close
                    </CButton>
                    <CButton onClick={() => handleSetCurate()} color="primary">Yes</CButton>
                </CModalFooter>
            </CModal>
        </div>
    );
};

export default Responses;
