import { projectDetailsEndPoints } from "./endPoints";
import { toast } from "react-toastify";
import { IaApiClient } from "src/network/iaApiClient";

 class ProjectRepository{
    createProject = async (body)=> {
        // Sample Body :-
    //     { 
    //         "name" : "Enable India",
    //         "description":"non profit organization",
    //         "owner":"Shanti Raghavan",
    //         "group_name":"Group1"
    //    }

        let data = await IaApiClient.post(projectDetailsEndPoints.createProject(), body).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while creating project");
        })
        return data;
    }

    updateProject = async (data,projectId)=> {
        // Sample data :-
        // {
        //     "languages" : ["english","hindi"],
        //     "status"    : "deploying",
        //      "questions"     :  [
        //         {
        //         "q_id" : "221de5e8-3c21-4397-b33d-e9111f133a4f",
        //         "q_text" : "what is your name?",
        //         "q_type" : "text"
        //         "sequence_no" : 1
        //     },
        //        {
        //         "q_id" : "480d8161-9517-405b-9fb3-91685cbabc68",
        //         "q_text" : "what is nonprofits mission?"
        //          "q_type" :"option"
        //          "options" : ["yes", "no"]
        //         "sequence_no" : 2
        //     }
        //     ]
        
        // }

        let result = await IaApiClient.put(projectDetailsEndPoints.updateProject(projectId), data).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while updating project");
        })
        return result;
    }

    getProjectDetailsById = async (projectId)=> {
        let data = await IaApiClient.get(projectDetailsEndPoints.getProjectDetailsById(projectId)).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while retreiving project details");
        })
        return data;
    }

    getProjectDetailsByUserId = async (owner)=> {
        let data = await IaApiClient.get(projectDetailsEndPoints.getProjectDetailsByUserId(owner)).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while retreiving project details");
        })
        return data;
    }

    getProjectSummary = async(projectId) => {
        let data = await IaApiClient.get(projectDetailsEndPoints.getProjectSummary(projectId)).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while retreiving project details");
        })
        return data;
    }

    getQuestionsSummary = async(projectId) => {
        let data = await IaApiClient.get(projectDetailsEndPoints.getQuestionsSummary(projectId)).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while retreiving questions details");
        })
        return data;
    }


    getDeployedProjectDetails= async (owner)=> {
        let data = await IaApiClient.get(projectDetailsEndPoints.getDeployedProjectDetails(owner)).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while retreiving project details");
        })
        return data;
    }


    getQrCode = async (body)=> {
        let data = await IaApiClient.post(projectDetailsEndPoints.getQrCode(), body).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while retreiving project details");
        })
        return data;
    }

    deleteProject = async (projectId)=> {
        let data = await IaApiClient.post(projectDetailsEndPoints.deleteProject(projectId)).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while deleting project");
        })
        return data;
    }

    //Don't remove this code

    // getassesmentFilterdata = async (projectId, body)=> {
    //     let data = await IaApiClient.get(projectDetailsEndPoints.getAssesmentFilterData(projectId), body).catch((err)=>{
    //         console.log("Error", err);
    //         toast.error("Something went wrong while retreiving filteres assessments");
    //     })
    //     return data;
    // }
 }

const projectRepository = new ProjectRepository();
export { projectRepository as ProjectRepository };