import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { CFormSwitch, CAlert } from '@coreui/react';
import { AssesmentRepository } from "src/repositories/assessmentRepository";
import { styled } from '@mui/material/styles';import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import  { tableCellClasses } from '@mui/material/TableCell';
import  { tablePaginationClasses } from '@mui/material/TablePagination';

const Discarded = ({ projectId, assessmentId }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [tableData, setTableData] = useState([]);
    const [projectIdRes, setProjectIdRes] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setProjectIdRes(projectId);
        getResponseUserDetails();
    }, []);

    const getResponseUserDetails = async () => {
        try {
            const response = await AssesmentRepository.getDiscardedResponses(assessmentId);
            setTableData(response.data);
        } catch (error) {
            console.error('Error retrieving user response details:', error);
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        if (words?.length <= wordLimit) return text;
        return words.slice(0, wordLimit).join(' ') + '...';
    };

    const renderedData = tableData?.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

    const questionMapping = {};
    let maxSequence = 0;
    tableData?.forEach(item => {
        item.responses?.forEach(response => {
            const sequenceNo = Number(response.sequence_no);
            questionMapping[sequenceNo] = response.q_text;
            maxSequence = Math.max(maxSequence, sequenceNo);
        });
    });

    const sortedSequenceNumbers = Array.from({length: maxSequence}, (_, i) => String(i + 1));

    const customTheme = (outerTheme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode,
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '--TextField-brandBorderColor': '#fff',
                        '--TextField-brandBorderHoverColor': '#fff',
                        '--TextField-brandBorderFocusedColor': '#fff',
                        '& label': {
                            color: '#9fffac',
                        },
                        '& label.Mui-focused': {
                            color: '#9fffac',
                        },
                    },
                },
            },

            // MuiFilledInput: {
            //   styleOverrides: {
            //     root: {
            //       '&::before, &::after': {
            //         borderBottom: '2px solid var(--TextField-brandBorderColor)',
            //       },
            //       '&:hover:not(.Mui-disabled, .Mui-error):before': {
            //         borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
            //       },
            //       '&.Mui-focused:after': {
            //         borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
            //       },
            //     },
            //   },
            // },
            MuiInput: {
                styleOverrides: {
                    root: {
                        '&::before': {
                            borderBottom: '2px solid var(--TextField-brandBorderColor)',
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                        },
                        '&.Mui-focused:after': {
                            borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                            // color:'#fff',
                            backgroundColor: '#252525'
                        },
                        '&.Mui-focused': {
                            backgroundColor: '#252525 !important',
                            caretColor: '#fff !important',
                            boxShadow: '0 0 #00800033 !important',
                            padding: '0 1rem !important',
                            color: ' #fff !important'
                        }
                    },
                },
            },
        },
    });


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#252525",
        border: '1px solid var(--secondary-dark-bg)',
        //   color: theme.palette.common.white,
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: "150%",
        color: '#d9d9d9',
        '::hover': {
            color: '#d9d9d9',
        }

    },
    
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        fontStyle: 'normal',
        border: '1px solid var(--secondary-dark-bg)',
        fontWeight: 300,
        fontSize: '16px',
        color: '#d9d9d9',
        backgroundColor: "#252525",
    }

}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type': {
        backgroundColor: '#252525',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        // border: 0,
    },
}));

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    '&:MuiTablePagination-root': {
        backgroundColor: '#252525',
        color: '#d9d9d9',
    },
    [`&.${tablePaginationClasses.root}`]: {
        backgroundColor: '#252525',
        color: '#d9d9d9',
    },
   
}));

    return (
        <div>
            {tableData?.length > 0 && (
                <div
                    className='flexgap1'
                    style={{
                        width: '100%',
                        marginBottom: '1rem',
                        height: '2.5rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <CFormSwitch
                            label='Expand/Collapse'
                            id="collapseExpandSwitch"
                            checked={isExpanded}
                            onChange={() => setIsExpanded(prevState => !prevState)}
                        />
                    </div>
                </div>
            )}
            <Paper>
                {tableData?.length > 0 ? (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell style={{ fontWeight: 'bold' }}>
                                            Rejected Reason
                                        </StyledTableCell>
                                        {sortedSequenceNumbers.map((sequence) => (
                                            <StyledTableCell key={sequence} style={{ fontWeight: 'bold' }}>
                                                {`Q${sequence}`}
                                            </StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {renderedData.map((row) => (
                                        <TableRow key={row.id}>
                                            <StyledTableCell style={{ minWidth: "10rem" }}>
                                                {row.discard_reason || 'N/A'}
                                            </StyledTableCell>
                                            {sortedSequenceNumbers.map(sequence => {
                                                const response = row.responses.find(response => String(response.sequence_no) === sequence);
                                                const text = response?.answer_text || '';
                                                const truncatedText = truncateText(text, 5);

                                                return (
                                                    <StyledTableCell style={{ minWidth: "10rem" }} key={sequence}>
                                                        {isExpanded ? text : truncatedText}
                                                    </StyledTableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StyledTablePagination
                            component="div"
                            count={tableData?.length}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            onPageChange={handlePageChange}
                            rowsPerPageOptions={[]}
                        />
                    </>
                ) : (
                    <CAlert color="warning">No rejected responses available to display.</CAlert>
                )}
            </Paper>
        </div>
    );
};

export default Discarded;