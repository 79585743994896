import React, { useState, useEffect, useRef } from 'react';
import { CButton, CBadge, CModal, CModalHeader, CTooltip, CModalTitle, CModalBody, CModalFooter, CSpinner, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { Link } from 'react-router-dom';
import { ProjectRepository } from 'src/repositories/projectRepository';
import { cilPencil, cilCloudUpload, cilBan, cilActionUndo, cilQrCode, cilDataTransferUp, cilTrash } from '@coreui/icons';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { useKeycloak } from "@react-keycloak/web";
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import  { tableCellClasses } from '@mui/material/TableCell';
import  { tablePaginationClasses } from '@mui/material/TablePagination';
import  { tableSortLabelClasses } from '@mui/material/TableSortLabel';

import { MuiTableHead, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, TextField, TableSortLabel } from '@mui/material';
import ellipsis from '../../assets/images/ellipsis-vertical-solid.svg';
import excelIcon from '../../assets/images/file-excel-regular.svg';
import { ToastContainer, toast } from 'react-toastify';
import shortid from 'shortid';
import Registration from './registration';
import deployIcon from '../../assets/images/deploy-svgrepo-com.svg';
import { GrDeploy } from "react-icons/gr";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { PiExportBold } from "react-icons/pi";
import { styled } from '@mui/material/styles';

const getBadge = status => {
    switch (status?.toLowerCase()) {
        case 'draft': return 'warning';  // Yellow, for attention but not critical
        case 'deployed': return 'success';  // Green, for successful operations
        case 'disabled': return 'danger';  // Red, for alerts and errors
        case 'deploying': return 'info';  // Blue, for ongoing processes
        case 'undeployed': return 'secondary';  // Grey, neutral for unused or default status
        case 'closed': return 'danger';  // Red, for alerts and errors
        default: return 'light';  // Light grey, for less critical or undefined statuses
    }
}

const DataCollectorComponent = () => {

    const [visible, setVisible] = useState(false);

    const [deployModal, setdeployModal] = useState(false);

    const [noQuesModal, setnoQuesModal] = useState(false);

    const [deleteModal, setDeleteModal] = useState(false);

    const [ViewModal, setViewModal] = useState(false);

    const [disableModal, setdisableModal] = useState(false);

    const [undeployModal, setundeployModal] = useState(false);

    const [QRModal, setQRModal] = useState(false);

    const [exportModal, setExportModal] = useState(false);

    const [QRCode, setQRCode] = useState(null);

    const [signedQRCode, setSignedQRCode] = useState(null);

    const [projectData, setProjectData] = useState([]);

    const [currentProject, setCurrentProject] = useState(null);

    const [currentProjectId, setCurrentProjectId] = useState(null);

    const [createModal, setCreateModal] = useState(false);
    
    const outerTheme = useTheme();

    const navigate = useNavigate();

    const { keycloak } = useKeycloak();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const regPage = useRef();
    const [modalTitle, setModalTitle] = useState(null);
    const [modalMessage, setModalMessage] = useState(null);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('updatedAt');


    useEffect(() => {
        // Fetch project data from the backend API

        fetchProjectData();

        // Set up an interval to fetch project data every 30 seconds
        const intervalId = setInterval(fetchProjectData, 30000); // 30000 milliseconds = 30 seconds

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures the effect runs only once on component mount


    const fetchProjectData = async () => {
        try {
            const response = await ProjectRepository.getProjectDetailsByUserId(keycloak.tokenParsed.preferred_username);
            setProjectData(response.data);
            // setProjectData(projectDummyData);         
        }
        catch {
            notify('Failed to to fetch project data');
            console.error('Failed to fetch project data:',);
        }
    };

    const submitReg = () => {
        if (regPage.current) {
            regPage.current.handleSubmit();
        }
    };

    const handleEdit = (currentProject) => {
        // Navigate to '/wizard' with the provided data
        // console.log('Edit project:', currentProject);
        const name = currentProject.name;
        const desc = currentProject.description;
        const project_id = currentProject.project_id;
        // navigate('/wizard', { state: { name, desc, project_id } });
        navigate(`/wizard/${project_id}`);
    }

    const viewProject = (currentProject) => {
        navigate(`/viewData/${currentProject}`);
    }
    const updateProject = (currentProjectId, status) => {
        ProjectRepository.updateProject({ status: status }, currentProjectId)
            .then(() => {
                // Proceed to the next step after updating the project
                fetchProjectData();
                setdeployModal(false);
                setdisableModal(false);
                setExportModal(false);
                setundeployModal(false);
            })
            .catch(error => {
                console.error('Failed to update project:', error);
            });
    }

    const QrCallback = (QRCode) => {
        ProjectRepository.getQrCode({ qrcodePath: QRCode }).then((response) => {
            setSignedQRCode(response.presignedURL);
        }).catch((error) => {
            console.log("QR Code error", error);
        })
    }

    const generateUniqueCode = () => {
        return shortid.generate();;
    };

    const exportToExcel = async (currentProjectId) => {
        setExportModal(false)
        try {
            // Fetch project details by project ID
            const response = await ProjectRepository.getProjectDetailsById(currentProjectId);
            const projectDetails = response.data[0];
            const formattedData = [];
            const choicesData = [];
            // Format project data to desired format
            projectDetails.questions.map(question => {
                let name = question.q_text.replace(/ /g, '_'); // Replace spaces with underscores
                let type = "";
                if (question.q_type === "Descriptive") {
                    type = "text";
                } else if (question.q_type === "Multiple Choice") {
                    const uniqueCode = generateUniqueCode();
                    type = "select_one " + uniqueCode;
                    question.options.forEach((option, index) => {
                        let optName = option.replace(/ /g, '_'); // Replace spaces with underscores
                        choicesData.push({
                            list_name: uniqueCode,
                            name: optName,
                            label: option
                        });
                    });
                } else if (question.q_type === "Location") {
                    type = "location"
                }
                formattedData.push({
                    type: type,
                    name: name,
                    label: question.q_text,
                    required: false
                });
            });

            // Convert data to Excel sheet
            const worksheet = XLSX.utils.json_to_sheet(formattedData);
            const worksheetChoices = XLSX.utils.json_to_sheet(choicesData);


            // Create a new Excel workbook
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'survey');
            XLSX.utils.book_append_sheet(workbook, worksheetChoices, 'choices');

            // Save the Excel file
            XLSX.writeFile(workbook, `${projectDetails.name}_details.xlsx`);
        } catch (error) {
            console.error('Failed to export to Excel:', error);
        }
    }

    const deleteProject = async (project_id) => {
        setDeleteModal(false)
        ProjectRepository.deleteProject(project_id).then(() => {
            fetchProjectData();
        }).catch((error) => {
            console.error('Failed to delete project:', error);
        });
    }

    const checkQuestions = async (project_id) => {
        projectData.map((project) => {
            if (project.project_id === project_id) {
                const hasFirstMessage = Array.isArray(project.template_messages)
                    ? project.template_messages.some((message) => message.message_type === "first")
                    : false;

                const hasLastMessage = Array.isArray(project.template_messages)
                    ? project.template_messages.some((message) => message.message_type === "last")
                    : false;

                if (!project.questions && (!hasFirstMessage || !hasLastMessage)) {
                    setModalTitle("Missing Questions and Template Messages");
                    setModalMessage("There are no questions and the required template messages (first or last) are missing. Please add them before deploying.");
                    setnoQuesModal(true);
                } else if (!project.questions) {
                    setModalTitle("No Questions");
                    setModalMessage("There are no questions in this project. Please add questions before deploying.");
                    setnoQuesModal(true);
                } else if (!hasFirstMessage || !hasLastMessage) {
                    setModalTitle("Missing Template Messages");
                    setModalMessage("The required template messages (first or last) are missing. Please add them before deploying.");
                    setnoQuesModal(true);
                } else {
                    setdeployModal(!deployModal);
                }
            }
        });
    };

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);

        const formattedDate = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });

        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
        });

        return `${formattedDate}`;
    };


    const filteredProjects = projectData.filter((project) =>
        project.name?.toLowerCase().includes(search?.toLowerCase()) ||
        project.description?.toLowerCase().includes(search?.toLowerCase()) ||
        project.status?.toLowerCase().includes(search?.toLowerCase()) ||
        formatDateTime(project.updatedAt)?.toLowerCase().includes(search?.toLowerCase())
    );

    const sortedProjects = filteredProjects.sort((a, b) => {
        if (orderBy === 'name') {
            return order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        }
        if (orderBy === 'updatedAt') {
            return order === 'asc' ? new Date(a.updatedAt) - new Date(b.updatedAt) : new Date(b.updatedAt) - new Date(a.updatedAt);
        }
        if (orderBy === 'responses_count') {
            return order === 'asc' ? a.responses_count - b.responses_count : b.responses_count - a.responses_count;
        }
        if (orderBy === 'status') {
            return order === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
        }
        return 0;
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = event => {
        setSearch(event.target.value);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const notify = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyInfo = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const customTheme = (outerTheme) =>
        createTheme({
            palette: {
                mode: outerTheme.palette.mode,
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '--TextField-brandBorderColor': '#fff',
                            '--TextField-brandBorderHoverColor': '#fff',
                            '--TextField-brandBorderFocusedColor': '#fff',
                            '& label': {
                                color: '#9fffac',
                            },
                            '& label.Mui-focused': {
                                color: '#9fffac',
                                fontSize: 20
                            },
                        },
                    },
                },

                // MuiFilledInput: {
                //   styleOverrides: {
                //     root: {
                //       '&::before, &::after': {
                //         borderBottom: '2px solid var(--TextField-brandBorderColor)',
                //       },
                //       '&:hover:not(.Mui-disabled, .Mui-error):before': {
                //         borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                //       },
                //       '&.Mui-focused:after': {
                //         borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                //       },
                //     },
                //   },
                // },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            '&::before': {
                                borderBottom: '2px solid var(--TextField-brandBorderColor)',
                            },
                            '&:hover:not(.Mui-disabled, .Mui-error):before': {
                                borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                            },
                            '&.Mui-focused:after': {
                                borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                                // color:'#fff',
                                backgroundColor: '#252525'
                            },
                            '&.Mui-focused': {
                                backgroundColor: '#252525 !important',
                                caretColor: '#d9d9d9 !important',
                                boxShadow: '0 0 #00800033 !important',
                                padding: '0 1rem !important',
                                color: '#d9d9d9 !important'
                            },
                            color: ' #d9d9d9 !important'
                        },
                    },
                },
            },
        });


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#252525",
            border: '1px solid var(--secondary-dark-bg)',
            //   color: theme.palette.common.white,
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: "150%",
            color: '#d9d9d9',
            fontFamily: 'Poppins',
            '::hover': {
                color: '#d9d9d9',
            }

        },
        
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16,
            fontStyle: 'normal',
            border: '1px solid var(--secondary-dark-bg)',
            fontWeight: 300,
            fontSize: '16px',
            color: '#d9d9d9',
            fontFamily: 'Poppins',
            backgroundColor: "#252525",
        }

    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type': {
            backgroundColor: '#252525',
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            // border: 0,
        },
    }));

    const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
        // backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.common.white,
        '&:MuiTablePagination-root': {
            backgroundColor: '#252525',
            color: '#d9d9d9',
        },
        [`&.${tablePaginationClasses.root}`]: {
            backgroundColor: '#252525',
            color: '#d9d9d9',
        }
    }));

    const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({

        [`&.${tableSortLabelClasses.root}`]: {
            color: '#d9d9d9',
        },
        [`&.${tableSortLabelClasses.icon}`]: {
            color: '#d9d9d9',
            fill: '#d9d9d9'
        },
        '&:MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon': {
             color: '#d9d9d9 !important',
            fill: '#d9d9d9'
        },
        [`&.${tableSortLabelClasses.iconDirectionAsc}`]: {
            color: '#d9d9d9',
            fill: '#d9d9d9'
        },
        [`&.${tableSortLabelClasses.iconDirectionDesc}`]: {
            color: '#d9d9d9',
            fill: '#d9d9d9'
        },
        [`&.${tableSortLabelClasses.root}.${tableSortLabelClasses.active}, .${tableSortLabelClasses.icon}`]: {
            color: '#d9d9d9 !important',
            fill: '#d9d9d9 !important'
        },
    }));



    if (projectData?.length == 0) {
        return (
            <>
                <div className="noDataPage">
                <div>
                    <ToastContainer />
                </div>
                    <div>
                        <h2 className='textcolor'>No Projects Available</h2>
                        <p className='textcolor'>Please create a new project to get started.</p>
                    </div>
                    {/* <Link to="/registration" className='newProject'>
                    <CButton color="primary">Create New Project</CButton>setCreateModal
                </Link> */}
                    <CButton className='newProject' onClick={() => setCreateModal(true)} style={{backgroundColor:"#9fffac",color:'black'}}>Create New Project</CButton>
                </div>
                <CModal
                    visible={createModal}
                    onClose={() => setCreateModal(false)}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader>
                        <CModalTitle>Create Project</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <Registration setCreateModal={setCreateModal} ref={regPage}></Registration>
                    </CModalBody>
                    {/* <CModalFooter>
                        <CButton color="secondary" onClick={() => setCreateModal(false)}>
                            Close
                        </CButton>
                        <CButton onClick={submitReg} style={{ width: '10rem' }}>Create Project{regPage.current && regPage.current.loading ? <CSpinner color="secondary" /> : ''}</CButton>
                    </CModalFooter> */}
                </CModal>
            </>
        );
    } else {
        return (
            <div>
                <div>
                    <ToastContainer />
                </div>
                <div className='mainHeaderDiv mb-2'>
                    <h3 className='mainTitle'>Your Projects</h3>
                    <div className='searchCreate'>
                        <div style={{ width: "15rem" }}>
                        <ThemeProvider theme={customTheme(outerTheme)}>

                            <TextField
                                fullWidth
                                label="Search Projects"
                                variant="standard"
                                color=""
                                onChange={handleSearchChange}
                                sx={{ m: 1 }}
                            />
                            </ThemeProvider>

                        </div>
                        <div style={{ display: 'flex' }}>
                            {/* <Link to="/registration">
                                <CButton color="primary">Create New Project</CButton>
                            </Link> */}
                            <CButton onClick={() => setCreateModal(true)} style={{backgroundColor:"#9fffac", color:'black'}}>  +  Create New Project</CButton>
                        </div>
                    </div>
                </div>
                {/* <p>This is my new component content.</p> */}
                <Paper sx={{ width: '100%', overflow: 'hidden', height: '100%' }} >
                    <TableContainer style={{minHeight: '20rem', backgroundColor: 'var(--primary-dark-bg)'}} >
                        <Table >
                            <TableHead > 
                                <TableRow >
                                    <StyledTableCell>
                                        Sl No
                                    </StyledTableCell>
                                    <StyledTableCell >  
                                        <StyledTableSortLabel
                                            active={orderBy === 'name'}
                                            direction={orderBy === 'name' ? order : 'asc'}
                                            onClick={() => handleRequestSort('name')}
                                        >
                                            Project Name
                                        </StyledTableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <StyledTableSortLabel
                                            active={orderBy === 'updatedAt'}
                                            direction={orderBy === 'updatedAt' ? order : 'asc'}
                                            onClick={() => handleRequestSort('updatedAt')}
                                        >
                                            Last Modified
                                        </StyledTableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <StyledTableSortLabel
                                            active={orderBy === 'responses_count'}
                                            direction={orderBy === 'responses_count' ? order : 'asc'}
                                            onClick={() => handleRequestSort('responses_count')}
                                        >
                                            Responses Count
                                        </StyledTableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <StyledTableSortLabel
                                            active={orderBy === 'status'}
                                            direction={orderBy === 'status' ? order : 'asc'}
                                            onClick={() => handleRequestSort('status')}
                                        >
                                            Status
                                        </StyledTableSortLabel>
                                    </StyledTableCell>
                                    <StyledTableCell>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell>{page * rowsPerPage + index + 1}</StyledTableCell>
                                        <StyledTableCell style={{ width: '20rem' }}>
                                            <CTooltip placement="top" content="View Project">
                                                <Link className='text-link' to={`/viewData/${project.project_id}`}>
                                                    {project.name}
                                                </Link>
                                            </CTooltip>
                                        </StyledTableCell>
                                        {/* <StyledTableCell>{project.description}</StyledTableCell> */}
                                        <StyledTableCell className='dateCell'>
                                            {formatDateTime(project.updatedAt)}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            {project.responses_count !== undefined && project.responses_count > 0 ?
                                                (
                                                    <CTooltip placement="top" content="view responses">
                                                        <Link className='text-link' to={`/summary/${project.project_id}/${encodeURIComponent(project.name)}`}>
                                                            {project.responses_count}
                                                        </Link>
                                                    </CTooltip>
                                                ) :
                                                (
                                                    // <Link className='text-link'>
                                                        0
                                                    // </Link>
                                                )
                                            }
                                        </StyledTableCell>
                                        <StyledTableCell style={{ width: '12rem' }}>
                                            <CBadge className='badgeSpan' color={getBadge(project.status)}>
                                                {project.status}
                                            </CBadge>
                                        </StyledTableCell>
                                        <StyledTableCell style={{ width: '9rem' }}>
                                            {/* <IconButton onClick={() => handleOpenEditModal(project)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleOpenUploadModal(project)}>
                                            <CloudUploadIcon />cilCalendar
                                        </IconButton> */}
                                            {/* 
                                        <CIcon icon={cilCloudUpload} onClick={() => { checkQuestions(project.project_id); setCurrentProjectId(project.project_id) }} style={{ cursor: "pointer" }} /> */}
                                            <div className='flexRow' style={{ float: 'right' }}>
                                                <div className='iconClass'>
                                                    {project.status?.toLowerCase() === 'draft' && (
                                                        <>
                                                            <CTooltip placement="top" content="Edit Project">
                                                                <CIcon
                                                                    icon={cilPencil}
                                                                    onClick={() => { setVisible(!visible); setCurrentProject(project); }}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </CTooltip>
                                                            <CTooltip placement="top" content="Deploy Project">
                                                                {/* <CIcon
                                                                    icon={cilCloudUpload}
                                                                    onClick={() => { checkQuestions(project.project_id); setCurrentProjectId(project.project_id); }}
                                                                    style={{ cursor: "pointer" }}
                                                                /> */}
                                                                <div onClick={() => { checkQuestions(project.project_id); setCurrentProjectId(project.project_id); }}
                                                                    style={{ cursor: "pointer" }} className='deployDiv'><GrDeploy  style={{color:'white'}}/> </div>
                                                            </CTooltip>
                                                        </>
                                                    )}
                                                    {project.status?.toLowerCase() === 'deployed' && (
                                                        <>
                                                            <CTooltip placement="top" content="Undeploy Project">
                                                                <CIcon
                                                                    icon={cilActionUndo}
                                                                    onClick={() => { setundeployModal(!undeployModal); setCurrentProjectId(project.project_id); }}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </CTooltip>
                                                            <CTooltip placement="top" content="Show QR Code">
                                                                <CIcon
                                                                    icon={cilQrCode}
                                                                    onClick={() => { setQRModal(!QRModal); setQRCode(project.qr_code_url); QrCallback(project.qr_code_url); }}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </CTooltip>
                                                        </>
                                                    )}
                                                    {project.status?.toLowerCase() === 'undeployed' && (
                                                        <>
                                                            <CTooltip placement="top" content="Edit Project">
                                                                <CIcon
                                                                    icon={cilPencil}
                                                                    onClick={() => { setVisible(!visible); setCurrentProject(project); }}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                            </CTooltip>
                                                            <CTooltip placement="top" content="Deploy Project">
                                                                {/* <CIcon
                                                                    icon={cilCloudUpload}
                                                                    onClick={() => { checkQuestions(project.project_id); setCurrentProjectId(project.project_id); }}
                                                                    style={{ cursor: "pointer" }}
                                                                /> */}
                                                                <div onClick={() => { checkQuestions(project.project_id); setCurrentProjectId(project.project_id); }}
                                                                    style={{ cursor: "pointer" }} className='deployDiv'><GrDeploy /></div>
                                                            </CTooltip>
                                                        </>
                                                    )}
                                                    <CDropdown>
                                                        {(project.status?.toLowerCase() !== 'deploying') && (
                                                            <CDropdownToggle className='nameTag userTag dropDown dropdown-toggleClass' color="info">
                                                                <IoEllipsisVerticalSharp style={{ cursor: "pointer", width: '1.3rem', height: '1.3rem',color:'white' }} />
                                                            </CDropdownToggle>
                                                        )}
                                                        <CDropdownMenu>
                                                            {project.status?.toLowerCase() === 'deployed' && (
                                                                <>
                                                                    <CDropdownItem icon={cilDataTransferUp} className='dropdownIconItem' onClick={() => { setExportModal(!exportModal); setCurrentProjectId(project.project_id) }} style={{ cursor: "pointer" }}>
                                                                        <PiExportBold style={{ cursor: "pointer", width: '1.3rem', height: '1.3rem',color:'white' }} />
                                                                        Export</CDropdownItem>
                                                                    <CDropdownItem onClick={() => { setdisableModal(!disableModal); setCurrentProjectId(project.project_id) }} className='dropdownIconItem'>
                                                                        <CIcon
                                                                            icon={cilBan}
                                                                            style={{ cursor: "pointer" }}
                                                                        />
                                                                        Close
                                                                    </CDropdownItem>
                                                                </>
                                                            )}
                                                            {(project.status?.toLowerCase() === 'undeployed' || project.status?.toLowerCase() === 'closed') && (
                                                                <>
                                                                    <CDropdownItem className='dropdownIconItem' onClick={() => { setExportModal(!exportModal); setCurrentProjectId(project.project_id) }} style={{ cursor: "pointer" }}>
                                                                        <img src={excelIcon} style={{ cursor: "pointer", width: '1.3rem', height: '1.3rem' }} />
                                                                        Export
                                                                    </CDropdownItem>
                                                                </>
                                                            )}
                                                            <CDropdownItem icon={cilBan} className='dropdownIconItem' onClick={() => { setDeleteModal(!deleteModal); setCurrentProjectId(project.project_id) }} style={{ cursor: "pointer" }}>
                                                                <CIcon
                                                                    icon={cilTrash}
                                                                    style={{ cursor: "pointer" }}
                                                                />
                                                                Delete
                                                            </CDropdownItem>
                                                        </CDropdownMenu>
                                                    </CDropdown>
                                                </div>
                                            </div>

                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <StyledTablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={filteredProjects?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
                <CModal
                    visible={visible}
                    onClose={() => setVisible(false)}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader onClose={() => setVisible(false)}>
                        <CModalTitle id="LiveDemoExampleLabel">Edit</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <p>Are you sure you want to edit this project?</p>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => setVisible(false)} className=''>
                            Close
                        </CButton>
                        <CButton color="primary" onClick={() => handleEdit(currentProject)}>Yes</CButton>
                    </CModalFooter>
                </CModal>
                <CModal
                    visible={deleteModal}
                    onClose={() => setDeleteModal(false)}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader onClose={() => setDeleteModal(false)}>
                        <CModalTitle id="LiveDemoExampleLabel">Delete Project</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <p>Are you sure you want to delete this project?</p>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => setDeleteModal(false)}>
                            Close
                        </CButton>
                        <CButton color="primary" onClick={() => deleteProject(currentProjectId)}>Yes</CButton>
                    </CModalFooter>
                </CModal>
                <CModal
                    visible={deployModal}
                    onClose={() => setdeployModal(false)}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader onClose={() => setdeployModal(false)}>
                        <CModalTitle id="LiveDemoExampleLabel">Deploy project</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <p>Are you sure you want to deploy this project?
                        </p><br />
                        <p>*Note: You can still add languages after deploying</p>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => setdeployModal(false)}>
                            Close
                        </CButton>
                        <CButton color="primary" onClick={() => updateProject(currentProjectId, "DEPLOYING")}>Yes</CButton>
                    </CModalFooter>
                </CModal>
                <CModal
                    visible={noQuesModal}
                    onClose={() => setnoQuesModal(false)}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader onClose={() => setnoQuesModal(false)}>
                        <CModalTitle id="LiveDemoExampleLabel">
                            {modalTitle}
                        </CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <p>{modalMessage}</p>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => setnoQuesModal(false)}>
                            Close
                        </CButton>
                    </CModalFooter>
                </CModal>
                <CModal
                    visible={disableModal}
                    onClose={() => setdisableModal(false)}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader>
                        <CModalTitle>Close project</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <p>Are you sure you want to close this project?</p>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => setdisableModal(false)}>
                            Close
                        </CButton>
                        <CButton color="primary" onClick={() => updateProject(currentProjectId, "DISABLED")}>Yes</CButton>
                    </CModalFooter>
                </CModal>
                <CModal
                    visible={exportModal}
                    onClose={() => setExportModal(false)}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader>
                        <CModalTitle>Export project</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <p>Are you sure you want to export this project?</p>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => setExportModal(false)}>
                            Close
                        </CButton>
                        <CButton color="primary" onClick={() => exportToExcel(currentProjectId)}>Yes</CButton>
                    </CModalFooter>
                </CModal>
                <CModal
                    visible={undeployModal}
                    onClose={() => setundeployModal(false)}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader>
                        <CModalTitle>Undeploy project</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <p>Are you sure you want to undeploy this project?</p>
                        <p>*Note: You can only add or remove languages after undeploying.</p>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => setundeployModal(false)}>
                            Close
                        </CButton>
                        <CButton color="primary" onClick={() => updateProject(currentProjectId, "UNDEPLOYED")}>Yes</CButton>
                    </CModalFooter>
                </CModal>
                <CModal
                    visible={QRModal}
                    onClose={() => { setQRModal(false); setSignedQRCode(null) }}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader>
                        <CModalTitle>QR Code</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <p className='mb-2'>Please scan this QR code to proceed with whatsapp chat</p>
                        {signedQRCode === null ? (
                            <CSpinner className='spinner' />
                        ) : (
                            <img style={{ width: "29rem" }} src={signedQRCode} alt="QR Code" />
                        )}
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => { setQRModal(false); setSignedQRCode(null) }}>
                            Close
                        </CButton>
                    </CModalFooter>
                </CModal>
                <CModal
                    visible={ViewModal}
                    onClose={() => setViewModal(false)}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader>
                        <CModalTitle>View Project</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <p>Are you sure you want to view this project?</p>
                    </CModalBody>
                    <CModalFooter>
                        <CButton color="secondary" onClick={() => setViewModal(false)} >
                            Close
                        </CButton>
                        <CButton color="primary" onClick={() => { viewProject(currentProjectId) }}>Yes</CButton>
                    </CModalFooter>
                </CModal>
                <CModal
                    visible={createModal}
                    onClose={() => setCreateModal(false)}
                    aria-labelledby="LiveDemoExampleLabel"
                >
                    <CModalHeader>
                        <CModalTitle>Create Project</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <Registration setCreateModal={setCreateModal} ref={regPage}></Registration>
                    </CModalBody>
                    {/* <CModalFooter>
                        <CButton color="secondary" onClick={() => setCreateModal(false)}>
                            Close
                        </CButton>
                        <CButton onClick={submitReg} style={{ width: '10rem' }}>Create Project{regPage.current && regPage.current.loading ? <CSpinner color="secondary" /> : ''}</CButton>
                    </CModalFooter> */}
                </CModal>
            </div>
        );
    }
}

export default DataCollectorComponent;