import React from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CTable, CTableBody, CTableHeaderCell, CTableRow, CTableDataCell } from '@coreui/react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const MultipleChoiceQuestionStats = ({ q_text, answeredCount, skippedCount, options, sequence_no }) => {
    const data = {
        labels: options.map(option => option.optionText),
        datasets: [
            {
                label: 'Answers',
                data: options.map(option => option.selectedPercentage),
                backgroundColor: '#9fffac',
            },
        ],
    };

    const opt = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                max: 100, // Set max value to 100 to ensure the chart represents percentages out of 100
                title: {
                    display: true,
                    text: 'Percentage', // Label for the x-axis
                    fontSize: 18,
                    color: '#d9d9d9'
                },
                ticks: {
                    color: '#d9d9d9', // Color of the x-axis labels
                },
                grid: {
                    color: '#ffffff44', // Color of the x-axis grid lines
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Choices', // Label for the y-axis
                    fontSize: 18,
                    color: '#d9d9d9'

                },
                grid: {
                    color: '#ffffff44', // Color of the x-axis grid lines
                },
                ticks: {
                    color: '#d9d9d9', // Color of the x-axis labels
                },
            },
        },
        plugins: {
            legend: {
              labels: {
                color: "#d9d9d9",
              },
            },
          },
    }

    return (
        <CCol xs="12" md="10" className="mb-4">
            <CCard className="shadow-sm qsummary-card">
                <CCardHeader>
                    <h5 className="m-0">Question {sequence_no}</h5>
                </CCardHeader>
                <CCardBody className="p-3">
                    <CRow className="mb-3">
                        <CCol>
                            <p>{q_text}</p>
                        </CCol>
                    </CRow>
                    <CRow className="mb-3">
                        <CCol xs="3" className='rowDiv'>
                            <div className="p-2">
                                <h6 className="text-center mb-1 flexCol">Answered: {answeredCount}</h6>
                                {/* <p className="h4 text-center mb-0"></p> */}
                            </div>
                        </CCol>
                        {/* <CCol xs="3">
                            <div className="p-2">
                                <h5 className="text-center mb-1">Skipped</h5>
                                <p className="h4 text-center mb-0">{skippedCount}</p>
                            </div>
                        </CCol> */}
                    </CRow>
                    <CRow className="mb-3">
                        <CCol>
                            <div className="p-3 rounded shadow-sm chart-summary">
                                <Bar options={opt} data={data} />
                            </div>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <CTable hover responsive className="table-outline mb-0 d-none d-sm-table multple-choice-table">
                                <thead className="thead-light">
                                    <CTableRow>
                                        <CTableHeaderCell>Choices</CTableHeaderCell>
                                        <CTableHeaderCell>Count</CTableHeaderCell>
                                        <CTableHeaderCell>Percentage</CTableHeaderCell>
                                    </CTableRow>
                                </thead>
                                <CTableBody>
                                    {options.map((option, index) => (
                                        <CTableRow key={index}>
                                            <CTableDataCell>{option.optionText}</CTableDataCell>
                                            <CTableDataCell>{option.selectedCount}</CTableDataCell>
                                            <CTableDataCell>{option.selectedPercentage}%</CTableDataCell>
                                        </CTableRow>
                                    ))}
                                    <CTableRow>
                                        <CTableDataCell><strong>Total</strong></CTableDataCell>
                                        <CTableDataCell>
                                            <strong>
                                                {options.reduce((total, option) => total + option.selectedCount, 0)}
                                            </strong>
                                        </CTableDataCell>
                                        <CTableDataCell>
                                        </CTableDataCell>
                                    </CTableRow>
                                </CTableBody>
                            </CTable>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    );
};

export default MultipleChoiceQuestionStats;
