import PropTypes from 'prop-types';
import { CCard, CCardBody, CButton, CBadge, CFormCheck, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react';
import { useNavigate } from 'react-router-dom';
import { ProjectRepository } from 'src/repositories/projectRepository';
import _ from 'lodash';
import { languageOptions } from './exportData';

export const Step3 = ({ selectedLanguages, setSelectedLanguages, projectId, saveChangesNavigate, initialLanguages, stepChanges }) => {
    const localId = projectId;
    const navigate = useNavigate();

    const handleClickNext = () => {
        saveChangesNavigate();
    }

    const handleClickPrev = () => {
        stepChanges(3, 1);
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        const selected = [...selectedLanguages];

        if (checked) {
            selected.push({ value, label: event.target.name });
        } else {
            // Remove the unselected language
            _.remove(selected, lang => lang.value === value);
        }

        setSelectedLanguages(selected);
    };

    return (
        <div>
            <CCard className="custom-card ccard">
                <CCardBody>
                    <CAccordion>
                        <CAccordionItem itemKey={1}>
                            <CAccordionHeader>Quick Guide for Selecting Language</CAccordionHeader>
                            <CAccordionBody style={{marginLeft:'2rem'}}>
                                <ol className="guide-list">
                                    <li>
                                        <strong>Please select the languages required for this project</strong>
                                    </li>
                                    <li>
                                        <strong>Click Save:</strong> Click &quot;Save&quot; to save the selected languages.
                                    </li>
                                </ol>
                            </CAccordionBody>
                        </CAccordionItem>
                    </CAccordion>
                </CCardBody>
            </CCard>

            <CCard style={{ marginTop: '1rem', marginBottom: '1rem' }} className='ccard'>
                <CCardBody>
                    <h5 style={{color:'#9fffac'}}>Select the languages required for this project</h5>
                    <div className="selected-languages-chips">
                        {selectedLanguages.map(lang => (
                            <CBadge key={lang.value} color="primary" style={{ margin: '0.25rem' }}>
                                {lang.label}
                            </CBadge>
                        ))}
                    </div>
                    <div className="language-checkboxes">
                        <div>
                            <CFormCheck
                                id="english"
                                name="English"
                                value="english"
                                label="English"
                                checked={true}
                                disabled={true}
                            />
                        </div>
                        {languageOptions
                            .filter(option => option.value !== 'english')
                            .map(option => (
                                <div key={option.value}>
                                    <CFormCheck
                                        id={option.value}
                                        name={option.label}
                                        value={option.value}
                                        label={option.label}
                                        checked={selectedLanguages.some(lang => lang.value === option.value)}
                                        onChange={handleCheckboxChange}
                                    />
                                </div>
                            ))}
                    </div>

                </CCardBody>
            </CCard>
            <div style={{ marginTop: '1rem',marginBlockStart:'14px', display: 'flex', gap: '1rem' }}>
                <CButton color="secondary" onClick={handleClickPrev}>Previous</CButton>
                <CButton color="primary" onClick={handleClickNext}>Save</CButton>
            </div>
        </div>
    );
};

Step3.propTypes = {
    prevStep: PropTypes.func.isRequired,
    selectedLanguages: PropTypes.array.isRequired,
    setSelectedLanguages: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
    initialLanguages: PropTypes.array.isRequired,
    saveChangesNavigate: PropTypes.func.isRequired,
};
