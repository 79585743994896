export const max_questions = 20;
export const max_users = 1000;
export const languageOptions = [
    { value: 'bengali', label: 'Bengali' },
    { value: 'english', label: 'English' },
    { value: 'gujarati', label: 'Gujarati' },
    { value: 'hindi', label: 'Hindi' },
    { value: 'kannada', label: 'Kannada' },
    { value: 'malayalam', label: 'Malayalam' },
    { value: 'marathi', label: 'Marathi' },
    { value: 'odia', label: 'Odia' },
    { value: 'portuguese', label: 'Portuguese' },
    { value: 'spanish', label: 'Spanish' },
    { value: 'tamil', label: 'Tamil' },
    { value: 'telugu', label: 'Telugu' },
    { value: 'urdu', label: 'Urdu' },
];
export const DataCollectorRefresh = 30 * 1000; //30 secs