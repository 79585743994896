export const standardizeDateFormat = (dateString, includeTime = false) => {
  const date = new Date(dateString);

  // Convert to IST
  const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30
  const istDate = new Date(date.getTime() + istOffset);

  const day = istDate.getDate();
  const monthIndex = istDate.getMonth();
  const year = istDate.getFullYear();

  // Array of month names
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  // Function to get the ordinal suffix for a given day
  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return 'th'; // 4-20 is always 'th'
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  // Get the ordinal suffix for the day
  const dayWithSuffix = day + getOrdinalSuffix(day);

  // Get the month name from the array
  const monthName = monthNames[monthIndex];

  // Initialize the formatted date string
  let formattedDate = `${dayWithSuffix} ${monthName} ${year}`;

  if (includeTime) {
    const hours = istDate.getHours();
    const minutes = istDate.getMinutes();
    const seconds = istDate.getSeconds();

    // Format the time to include leading zeros where necessary
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

    formattedDate += ` at ${formattedTime}`;
  }

  // Return the formatted date string
  return formattedDate;
}

