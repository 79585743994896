import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    CCard, CCardBody, CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CAccordion,
    CAccordionHeader,
    CAccordionBody,
    CAccordionItem
} from '@coreui/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useEffect } from 'react';
import trashIcon from '../../assets/images/trash-can-regular.svg';
import CIcon from '@coreui/icons-react'
import { cilCloudDownload, cilCloudUpload } from '@coreui/icons';
import questionsTemplate from '../../assets/temp/questions_template.xlsx';
import _ from 'lodash';
import { max_questions } from './exportData';
import gripDots from '../../assets/images/grip-dots-vertical-svgrepo-com.svg';
import emptyIllustration from '../../assets/images/No data-cuate.svg'


export const Step1 = ({ nextStep, questions, onFileChange, onDragEnd, onDeleteQuestion, file, projectId, stepChanges }) => {
    // const localId = projectId;
    const [initialQuestionsData, setInitialQuestionsData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [qid, setQid] = useState('');
    useEffect(() => {
        // Save the initial questions data when component mounts
        setInitialQuestionsData(questions || []);
    }, []);
    const handleClickNext = () => {
        stepChanges(1, 3);
    };
    const handleClickPrev = () => {
        stepChanges(1, 0);
    };

    return (
        <div>
            <CCard className="custom-card ccard">
                <CCardBody> 
                    <CAccordion>
                        <CAccordionItem itemKey={1}>
                            <CAccordionHeader>Quick Guide for Uploading Questions</CAccordionHeader>
                            <CAccordionBody style={{marginLeft:'2rem'}}>
                                <ol className="guide-list">
                                    <li><strong>Please use only English text in the documents</strong></li>
                                    <li><strong>Download the Template:</strong> Click "Download Template" to save the required file. Make sure you get the right version for your needs.</li>
                                    <li><strong>Fill Out the Template:</strong> Open the file with the appropriate software and fill in all fields accurately. Save your changes.</li>
                                    <li><strong>Upload Completed File:</strong> Return to this page, click "Choose File", and select the completed document to upload.</li>
                                    <li><strong>Descriptive options:</strong> These options indicate the question can be audio or text.</li>
                                    <li><strong>Max questions allowed:</strong> {max_questions}</li>
                                    <li><strong>Max characters allowed in each options:</strong> 24. Options have to be separated by semi colon (;)</li>
                                    <li>
                                        <strong>Modify Questions:</strong> If you need to make changes after uploading, you can do so by following the instructions provided.
                                        <br /><br />
                                        <ol className='nested'>
                                            <li><strong>Delete Questions:</strong> If you want to delete any questions, click the delete icon present in the right corner of the question.</li>
                                            <li><strong>Reorder Questions:</strong> Drag and drop the questions tile to reorder.</li>
                                            <li><strong>Add New Question:</strong> If you have to add a new question, please make the required changes in the file you have uploaded and reupload the updated file.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </CAccordionBody>
                        </CAccordionItem>
                    </CAccordion>
                    <div className='one-gap' style={{marginTop: '1rem'}}>
                        <CButton href={questionsTemplate} download="questions_template.xlsx" color="primary" variant="outline" className="custom-file-input">
                            <CIcon icon={cilCloudDownload} customClassName="tempIcon" />
                            Download Template
                        </CButton>
                    </div>
                </CCardBody>
            </CCard>
            {/* <CCard className="custom-card">
                <CCardBody>
                    <div className='fileDiv'>
                        <CButton href={questionsTemplate} download="questions_template.xlsx" color="primary" className="custom-file-input labelBtn">
                            <CIcon icon={cilCloudDownload} customClassName="tempIcon" />
                            Download Template
                        </CButton>
                        <label htmlFor="fileInput" className="custom-file-input labelBtn">
                            <CIcon icon={cilCloudUpload} customClassName="tempIcon" />
                            Choose File
                        </label>
                        <input type="file" accept=".xlsx, .xls" id="fileInput" onChange={onFileChange} style={{ display: 'none' }} />
                        <p>Selected file: {file?.name || 'No file chosen'}</p>
                    </div>
                </CCardBody>
            </CCard> */}


            {/* <CButton href="/path/to/template/file.xlsx" download="Template.xlsx" color="primary">Download Template</CButton> */}
            {questions?.length === 0 ? (
                <>
                    {/* <CCard style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                        {/* <div className='one-gap' style={{ padding: '2rem' }}>
                            <div className='flexgap1 flex-evenly'>
                                <label htmlFor="fileInput" className="custom-file-input labelBtn">
                                    <CIcon icon={cilCloudUpload} customClassName="tempIcon" />
                                    Choose File
                                </label>
                                <input type="file" accept=".xlsx, .xls" id="fileInput" onChange={onFileChange} style={{ display: 'none' }} />
                                <p>Selected file: {file?.name || 'No file chosen'}</p>
                            </div></div> }
                        <CCardBody>
                            <div className="empty-placeholder">
                                <img src={emptyIllustration} style={{width:'20rem',height:'14rem'}}/>
                                <h2>No Questions Added</h2>
                                <p>Please choose a file for questions to appear here.</p>
                            </div>
                        </CCardBody>
                    </CCard> */}
                    <CCard style={{ marginTop: '1rem', marginBottom: '1rem' }} className='ccard'>
                        <CCardBody>
                            <label htmlFor="fileInput" className="file-select-area" style={{ cursor: 'pointer', textAlign: 'center', display: 'block' }}>
                                <div className="empty-placeholder" style={{ padding: '2rem', border: '2px dashed #ccc', borderRadius: '8px' }}>
                                    <img src={emptyIllustration} alt="Select File" style={{ width: '20rem', height: '14rem', marginBottom: '1rem' }} />
                                    <h5 style={{ marginBottom: '1rem' }}>No Questions Added</h5>
                                    <h6 style={{ marginBottom: '1rem' }}>Please click here to choose a file</h6>
                                </div>
                            </label>
                            <input type="file" accept=".xlsx, .xls" id="fileInput" onChange={onFileChange} style={{ display: 'none' }} />
                        </CCardBody>
                    </CCard>

                </>

            ) : (
                <CCard style={{ marginTop: '1rem', marginBottom: '1rem' }} className='ccard'>
                    <div className='step1NumDiv'>
                        <div className='one-gap'>
                            <div className='flexRow countBg'><h5 >Total number of questions:</h5><div className="slNumbers">{questions?.length}</div></div>
                            <div className='flexgap1 flex-evenly'>
                                <label htmlFor="fileInput" className="custom-file-input labelBtn">
                                    <CIcon icon={cilCloudUpload} customClassName="tempIcon" />
                                    Choose File
                                </label>
                                <input type="file" accept=".xlsx, .xls" id="fileInput" onChange={onFileChange} style={{ display: 'none'}} />
                                <p style={{color: 'var(--primary)'}}>Selected file: {file?.name || 'No file chosen'}</p>
                            </div>
                            {/* <div className='flexRow countBg'><h5>Total number of multiple questions:</h5><span className="slNumbers">{questions.filter(question => question.q_type === 'Multiple Choice')?.length}</span></div> */}
                        </div>
                        {/* <CTooltip content="These questions can be reordered on drag.">
                            <CIcon icon={cilListLowPriority}></CIcon>
                        </CTooltip> */}
                    </div>
                    <CCardBody>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="questions">
                                {(provided) => (
                                    <ul {...provided.droppableProps} ref={provided.innerRef} className="questions-list">
                                        {questions.map((question, index) => (
                                            <Draggable key={question.q_id} draggableId={question.q_id} index={index}>
                                                {(provided) => (
                                                    <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className="question-item">
                                                        <div className="quesDiv">
                                                            <img style={{ width: "1rem" }} src={gripDots} />
                                                            <span className="slNumbers">{`${index + 1}`}</span>
                                                            <span className="question-order">{question.q_text}</span>
                                                            {question.q_type == 'Descriptive' && <div className='nameTag userTag'>{question.q_type}</div>}
                                                            {question.q_type == 'Multiple Choice' &&
                                                                <CDropdown>
                                                                    <CDropdownToggle className='nameTag userTag' color="info">Multiple Choice <span className='multipleDrop'>▼</span></CDropdownToggle>
                                                                    <CDropdownMenu>
                                                                        {question.options.map((option, index) => (
                                                                            <CDropdownItem className='nameTag flexStart' key={index}>{option}</CDropdownItem>
                                                                        ))}
                                                                    </CDropdownMenu>
                                                                </CDropdown>
                                                            }
                                                            {question.q_type == 'Location' && <div className='nameTag userTag'>{question.q_type}</div>}
                                                            {/* {question.q_type == 'Multiple Choice' && <div className='nameTag phoneTag'>{question.options.join(', ')}</div>} */}
                                                            {/* {question.questionType} */}
                                                        </div>
                                                        {/* <button onClick={() => onDeleteQuestion(question.id)} className="delete-button">X</button> */}
                                                        {/* <button onClick={() => onDeleteQuestion(question.id)}><img style={{height:'1.5rem',width:'1.5rem'}} src={trashIcon}/></button> */}
                                                        {/* <img onClick={() => onDeleteQuestion(question.id)} style={{ height: '1.5rem', width: '1.5rem' }} src={trashIcon} /> */}
                                                        <button className="delete-button" onClick={() => { setQid(question.q_id); setVisible(true) }}>
                                                            <img style={{ height: '1.5rem', width: '1.5rem' }} src={trashIcon} alt="Delete" />
                                                        </button>
                                                    </li>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </CCardBody>
                </CCard>
            )}
            <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }} >
                <CButton color="secondary" onClick={handleClickPrev}>Previous</CButton>
                <CButton color="primary" onClick={handleClickNext}>Next</CButton>
            </div>
            <CModal
                visible={visible}
                onClose={() => { setVisible(false); setQid('') }}
                aria-labelledby="LiveDemoExampleLabel"
            >
                <CModalHeader onClose={() => { setVisible(false); setQid('') }}>
                    <CModalTitle id="LiveDemoExampleLabel">Delete</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    {/* <p>{qid}</p> */}
                    <p>Are you sure you want to delete this question?</p>
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={() => { setVisible(false); setQid('') }}>
                        Close
                    </CButton>
                    <CButton color="primary" onClick={() => { onDeleteQuestion(qid); setVisible(false); setQid('') }}>Yes</CButton>
                </CModalFooter>
            </CModal>
        </div>
    );
};

Step1.propTypes = {
    nextStep: PropTypes.func.isRequired,
    questions: PropTypes.array.isRequired,
    onFileChange: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
    onDeleteQuestion: PropTypes.func.isRequired,
    file: PropTypes.object,
    projectId: PropTypes.string,
    stepChanges: PropTypes.func.isRequired,
};