import {
    CFormTextarea,
    CFormInput,
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CRow,
    CSpinner,
} from '@coreui/react';
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectRepository } from 'src/repositories/projectRepository';
import { useKeycloak } from '@react-keycloak/web';
import { ToastContainer, toast } from 'react-toastify';

const Registration = forwardRef((props, ref) => {
    let navigate = useNavigate();
    const { setCreateModal } = props;
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [nameError, setNameError] = useState('');
    const [descError, setDescError] = useState('');
    const { keycloak } = useKeycloak();
    const [userGroups, setUserGroups] = useState([]);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [loading, setLoading] = useState(false); // Use useState for loading

    useEffect(() => {
        // Fetch user's groups when component mounts
        if (keycloak.authenticated) {
            keycloak.loadUserInfo().then((userInfo) => {
                const sanitizedGroups = userInfo.groups?.map((group) =>
                    group.startsWith('/') ? group.slice(1) : group
                );
                setUserGroups(sanitizedGroups && sanitizedGroups[0] ? sanitizedGroups[0] : 'Default Group');
            });
        }
    }, [keycloak.authenticated]);

    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameError(''); // Clear the error when user starts typing
        checkFormValidity(e.target.value, desc);
    };

    const handleDescChange = (e) => {
        setDesc(e.target.value);
        setDescError(''); // Clear the error when user starts typing
        checkFormValidity(name, e.target.value);
    };

    const notify = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const handleSubmit = () => {
        setLoading(true); // Set loading to true
        // if (name.trim() === '') {
        //     setNameError('Name must be filled out');
        //     setLoading(false); // Reset loading if there's an error
        //     return;
        // }
        // if (desc.trim() === '') {
        //     setDescError('Description must be filled out');
        //     setLoading(false); // Reset loading if there's an error
        //     return;
        // }

        ProjectRepository.createProject({
            name: name,
            description: desc,
            owner: keycloak.tokenParsed.preferred_username,
            group_name: userGroups,
        })
            .then((response) => {
                setLoading(false); // Reset loading after response
                if (response.code === 201) {
                    const data = response.payload.data;
                    navigate(`/wizard/${data.project_id}`);
                } else {
                    console.error(`Error: ${response.status}`);
                    notify('there seems to be an error in creating this project please try again');
                    // Handle non-200 status codes as needed
                }
            })
            .catch((error) => {
                setLoading(false); // Reset loading after error
                console.error(`Error: ${error}`);
                notify('there seems to be an error in creating this project please try again');
                // Handle error as needed
            });
    };

    // Expose functions and state to parent via ref
    useImperativeHandle(ref, () => ({
        handleSubmit,
        loading,
        resetForm() {
            setName('');
            setDesc('');
            setNameError('');
            setDescError('');
            console.log('Form has been reset!');
        },
    }));

    const checkFormValidity = (nameValue, descValue) => {
        if (nameValue.trim() && descValue.trim()) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    };

    return (
        <>
            <div>
                <ToastContainer />
            </div>
            <CRow>
                <CCol xs="12" md="12">
                    <label className="form-label">Project Name:</label>
                    <CFormInput type="text" value={name} onChange={handleNameChange} placeholder="Eg: Market Research" />
                    {nameError && <p style={{ color: 'red' }}>{nameError}</p>}
                    <label style={{ marginTop: '1rem' }} className="form-label">Description:</label>
                    <CFormTextarea
                        className='custom-textarea'
                        value={desc}
                        onChange={handleDescChange}
                        placeholder="Eg: Gather insights on consumer satisfaction with our latest product offerings."
                        rows="4"
                    />
                    {descError && <p style={{ color: 'red' }}>{descError}</p>}

                    {/* {loading && <CSpinner color="primary" />} Show spinner while loading */}
                </CCol>
            </CRow>
            <hr />
            <CRow>
                <CCol xs="12" md="12" className='pt-4'>
                    <div className='flexEnd'>
                        <CButton color="secondary" onClick={() => setCreateModal(false)}>
                            Close
                        </CButton>
                        <CButton onClick={handleSubmit} style={{ width: '10rem' }} disabled={isSubmitDisabled}>
                            Create Project
                            {loading ? <CSpinner  className="spinner" style={{ marginLeft: '1rem' }} size='sm' /> : ''}
                        </CButton>
                    </div>
                </CCol>
            </CRow>
        </>
    );
});

export default Registration;
