import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CModal, CModalHeader, CModalBody, CModalFooter } from '@coreui/react';
import { ResponsesRepository } from 'src/repositories/responseRepository';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, } from '@mui/material';


import { standardizeDateFormat } from '../table/utils';

const DescriptiveQuestionStats = ({ q_text, answeredCount, skippedCount, sequence_no, projectId, q_id }) => {
  const [modal, setModal] = useState(false);
  const [responses, setResponses] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const toggleModal = async (q) => {
    setModal(q);
    if (q) {
      await getResponses();
    }
    if (!q) {
      setResponses([]);
    }
  };

  const getResponses = async () => {
    try {
      const response = await ResponsesRepository.getResponseDetails(projectId, q_id);
      setResponses(response.data);
    } catch (error) {
      console.error('Failed to fetch questions data', error);
    }
  };

  const totalPages = Math.ceil((responses?.length || 0) / rowsPerPage);

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Get the current page responses
  const currentResponses = responses?.slice((page - 1) * rowsPerPage, page * rowsPerPage) || [];



  return (
    <CCol xs="12" md="10" className="mb-4">
      <CCard className="shadow-sm qsummary-card">
        <CCardHeader>
          <p className="m-0 ">Question {sequence_no}</p>
        </CCardHeader >
        <CCardBody className="p-3">
          <CRow className="mb-3">
            <CCol>
              <p >{q_text}</p>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="3" className='rowDiv'>
              <div className="p-2">
                <h6 className="text-center mb-1 flexCol ">Answered: {answeredCount}</h6>
                {/* <p className="h4 text-center mb-0"></p> */}
              </div>
              {answeredCount > 0 && (
                <CButton variant='ghost' onClick={() => toggleModal(true)}>View answers</CButton>
              )}
            </CCol>
            {/* <CCol xs="3">
              <div className="p-2">
                <h5 className="text-center mb-1">Skipped</h5>
                <p className="h4 text-center mb-0">{skippedCount}</p>
              </div>
            </CCol> */}
            {/* <CCol xs="6" className="text-right">
              
            </CCol> */}
          </CRow>
        </CCardBody>
      </CCard>

      <CModal size="xl" visible={modal} onClose={() => toggleModal(false)}>
        <CModalHeader closeButton className="modal-border-bottom">Question: {sequence_no}</CModalHeader>
        <CModalBody>
          <h6>{q_text}</h6>
          {responses?.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table className='multple-choice-table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Submitted at</TableCell>
                      <TableCell>Response</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentResponses.map((response, index) => (
                      <TableRow key={index}>
                        <TableCell>{(page - 1) * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{standardizeDateFormat(response.updatedAt, true)}</TableCell>
                        <TableCell>{response.response_text}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
                style={{ marginTop: '1rem', display: 'flex', justifyContent: 'center',color:'#9fffac' }}
              />
            </>
          ) : (
            <p className='textcolor'>Please wait.....</p>
          )}
        </CModalBody>
        <CModalFooter className='modal-border-top'>
          <CButton color="secondary" onClick={() => toggleModal(false)}>Close</CButton>
        </CModalFooter>
      </CModal>
    </CCol>
  );
};

export default DescriptiveQuestionStats;
