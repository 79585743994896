import { HTTP_STATUS_CODES, serverErrors } from "./constants";
export const successHandler = (axiosResponse) => {
  //server data sent here.
  if (axiosResponse.data.responseCode === HTTP_STATUS_CODES.noContent) {
    return Promise.resolve({});
  }
  return Promise.resolve(axiosResponse.data);
};
export const errorHandler = (error) => {
  console.log(`API error:  ${JSON.stringify(error)}`);
  let errorMessage = serverErrors.SERVER_ERROR;
  if (error?.message && error?.message === "Network Error") {
    console.log(`Network Error. Error: ${JSON.stringify(error)}`);
    //TODO Deal no network error
  }
  if (error?.response?.status >= 500) {
    errorMessage = serverErrors.SERVER_ERROR;
    return errorMessage;
  }
  if (error?.response?.status >= 400 && error?.response?.status < 500) {
    errorMessage = serverErrors.CLIENT_ERROR;
    return errorMessage;
  }
  if (error?.response) {
    const data  = error?.response?.data;
    if (data) {
      // const errorMessage = (data as { errorMessage: string })?.errorMessage;
      errorMessage = data
    }
  } else {
    console.log(`Don't know the error. Error: ${JSON.stringify(error)}`);
  }

  console.log("cnxbsnvds", errorMessage, "rescbnscvsa", error?.response?.data);
  return Promise.resolve(JSON.stringify(error));
};
