import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import PrivateRoute from 'src/helpers/PrivateRoute'
// import {Assesment} from './views/assesment/Assesment'
import DefaultLayout from './layout/DefaultLayout'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" color='var(--primary)'></div>
  </div>
)

// Containers
// const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))


class App extends Component {


  render() {
    
    return (
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route path="*" name="Home" element={<PrivateRoute><DefaultLayout /></PrivateRoute>} />
              {/* <Route path="/assessments" name="Home" element={<PrivateRoute><Assesment /></PrivateRoute>} /> */}
            </Routes>
          </Suspense>
        </HashRouter>
    )
  }
}

export default App
