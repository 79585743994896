import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilEnvelopeOpen, cilList, cilMenu } from '@coreui/icons'

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import { logo } from 'src/assets/brand/logo'
import { useKeycloak } from "@react-keycloak/web";


const AppHeader = () => {
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate()


  useEffect(() => {
    if (keycloak.authenticated) {
      navigate('/projects')  // using navigate instead of history.push
    }
  }, [])


  return (
    <CHeader position="fixed" className="mb-4 "  style={{paddingTop: "10px"}}>
      <CContainer fluid>
       
        <CHeaderBrand className="mx-auto d-md-none fixed" to="/">
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          {!!keycloak.authenticated && (
            <CNavItem>
               <a className="navbar-brand" href="https://apurva.ai" >Apurva<span className="navbar-brand-dot">.</span>ai</a>
            </CNavItem>
          )}
         
        </CHeaderNav>
        <CHeaderNav>
          <CNavItem>
          
            {!keycloak.authenticated && (

              <CNavLink href="#" onClick={() =>
                keycloak.login()}>Login</CNavLink>
            )}
            
          </CNavItem>
         
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown username={keycloak.tokenParsed.preferred_username} />
        </CHeaderNav>
      </CContainer>
     
    </CHeader>
  )
}

export default AppHeader
