import React from 'react'
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import {
  cilAccountLogout,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import avatar8 from './../../assets/images/Default-Profile-Picture-Transparent.png'
import { useKeycloak } from "@react-keycloak/web";

const AppHeaderDropdown = (prop) => {
  const { keycloak } = useKeycloak();

  return (
    <CDropdown variant="nav-item" style={{marginBottom:'6px'}}>
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
      <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.1198 12.78C12.0401 12.77 11.9595 12.77 11.8798 12.78C11.0317 12.7514 10.228 12.3944 9.63831 11.7842C9.04866 11.174 8.71929 10.3585 8.71977 9.50999C8.71977 7.69999 10.1798 6.22999 11.9998 6.22999C12.8592 6.22849 13.6848 6.5648 14.2986 7.16639C14.9124 7.76798 15.2652 8.58668 15.2809 9.44597C15.2966 10.3053 14.9741 11.1363 14.3827 11.76C13.7914 12.3837 12.9787 12.75 12.1198 12.78ZM18.7398 19.38C16.902 21.0691 14.4958 22.0044 11.9998 22C9.39977 22 7.03977 21.01 5.25977 19.38C5.35977 18.44 5.95977 17.52 7.02977 16.8C9.76977 14.98 14.2498 14.98 16.9698 16.8C18.0398 17.52 18.6398 18.44 18.7398 19.38Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
           stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end" style={{marginTop:'10px'}}>
        <CDropdownItem href="#">
          {/* <CIcon icon={cilAcc} className="me-2" /> */}
          Logged in as {prop.username}
        </CDropdownItem>
        {/* <hr/> */}
        <CDropdownItem onClick={() => {keycloak.logout()}}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
          {/* <CBadge color="success" className="ms-2">
            42
          </CBadge> */}
        </CDropdownItem>
              </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
