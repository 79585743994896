import React, { useState, useRef } from 'react';
import {
    CCard, CCardBody, CButton, CCardHeader, CSpinner, CAccordion,
    CAccordionItem,
    CAccordionHeader,
    CAccordionBody
} from '@coreui/react';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ProjectRepository } from 'src/repositories/projectRepository';
import { UserRepository } from 'src/repositories/userRepository';
import CIcon from '@coreui/icons-react'
import { cilSave } from '@coreui/icons';
import _ from 'lodash';
import { max_questions, max_users } from './exportData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step1Static } from './Step1Static';
import { useNavigate } from 'react-router-dom';
import messages from '../../assets/images/messages-1-svgrepo-com.svg';
import questionsIcon from '../../assets/images/question.png';
import languageIcon from '../../assets/images/language-svgrepo-com.svg'
var cleanedProjectId;
// WizardComponent with state lifted up
const WizardComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [desc, setDesc] = useState([]);
    const [initialDesc, setinitialDesc] = useState([]);
    const [name, setName] = useState([]);
    const [status, setStatus] = useState([]);
    const [startMessage, setStartMessage] = useState('');
    const [endMessage, setEndMessage] = useState('');
    const [initialStartMessage, setInitialStartMessage] = useState('');
    const [initialEndMessage, setInitialEndMessage] = useState('');
    const [step, setStep] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [initialQuestionsData, setInitialQuestionsData] = useState([]);
    const [initialContactsData, setInitialContactsData] = useState([]); // Add a new state for contacts [Step 2
    const [contacts, setContacts] = useState([]); // Add a new state for contacts
    const [file, setFile] = useState(null);
    const [contactsFile, setContactsFile] = useState(null); // Add a new state for the file [Step 2
    const [error, setError] = useState(null);
    const [changesPresent, setchangesPresent] = useState(false);
    const [isEditingDesc, setIsEditingDesc] = useState(false);
    const [editableDesc, setEditableDesc] = useState(desc);
    const [selectedLanguages, setSelectedLanguages] = useState([{ value: 'english', label: 'English' }]);
    const [initialLanguages, setInitialLanguages] = useState([{ value: 'english', label: 'English' }]);
    const [userFiles, setuserFiles] = useState([]);
    const spanRef = useRef();
    const textAreaRef = useRef();
    const [isPageLoading, setIsPageLoading] = useState(true);
    useEffect(() => {
        const cleanId = cleanProjectId(projectId);
        cleanedProjectId = cleanId;
        const fetchData = async () => {
            try {
                const res = await ProjectRepository.getProjectDetailsById(cleanedProjectId);
                // const contactsRes = await UserRepository.getUserDetails(cleanedProjectId);
                const contactsRes = '';
                const data = res.data[0];
                if (data) {
                    setIsPageLoading(false);
                    var contactsData;
                    if (contactsRes && contactsRes.data) {
                        contactsData = contactsRes.data.map(contact => ({
                            ...contact,
                            phone_no: parseInt(contact.phone_no, 10), // Convert the phone_no property to an integer
                            isFieldOfficer: contact.is_field_officer ? "yes" : "no"
                        }));
                    }
                    if (data.status) {
                        if (data.status === 'DRAFT') {
                            setStatus('draft');
                        } else if (data.status === 'UNDEPLOYED') {
                            setStatus('undeployed');
                        } else {
                            navigate('/projects');
                        }
                    }
                    if (contactsData) {
                        setContacts(contactsData); // Set the contacts state with converted phone numbers
                        setInitialContactsData(contactsData);
                    }
                    if (data.questions) {
                        setQuestions(data.questions); // Set the questions state
                        setInitialQuestionsData(data.questions); // Set the initial questions data
                    }
                    if (data.name) {
                        setName(data.name);
                    }
                    if (data.description) {
                        setDesc(data.description);
                        setinitialDesc(data.description);
                    }
                    if (data.languages) {
                        const transformedLanguages = transformLanguages(data.languages);
                        setSelectedLanguages(transformedLanguages);
                        setInitialLanguages(transformedLanguages);
                    }
                    if (data.template_messages) {
                        const firstMessage = data.template_messages.find(msg => msg.message_type === 'first');
                        const lastMessage = data.template_messages.find(msg => msg.message_type === 'last');

                        if (firstMessage) {
                            setStartMessage(firstMessage.message_text);
                            setInitialStartMessage(firstMessage.message_text);
                        }

                        if (lastMessage) {
                            setEndMessage(lastMessage.message_text);
                            setInitialEndMessage(lastMessage.message_text);
                        }
                    }
                    // set your state based on the fetched data
                }
            } catch (error) {
                console.error('Failed to fetch project details:', error);
                // notify('Failed to fetch project details');
            }
        };

        if (cleanedProjectId) {
            fetchData();
        } else {
            navigate('/projects');
        }
        setIsLoading(false);
    }, [projectId]);

    function transformLanguages(languages) {
        return languages.map(language => ({
            value: language?.toLowerCase(),
            label: language.charAt(0).toUpperCase() + language.slice(1) // Capitalize the first letter
        }));
    }

    // const calculateTextWidth = () => {
    //     return `${spanRef.current.offsetWidth + 10}px`; // +10 for some padding
    // };

    // useEffect(() => {
    //     if (spanRef.current) {
    //         inputRef.current.style.width = calculateTextWidth();
    //     }
    // }, [desc]);

    const calculateTextWidth = () => {
        const textWidth = spanRef.current.offsetWidth + 10; // Calculate text width with padding
        return `${textWidth}px`; // Use the smaller value between calculated text width and max width
    };

    useEffect(() => {
        if (spanRef.current && textAreaRef.current) {
            // textAreaRef.current.style.width = calculateTextWidth(); // Set width
            // Adjust height dynamically based on the scrollHeight of the textarea
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    }, [desc]);

    const cleanProjectId = (id) => {
        if (!id.includes('&')) {
            return id;
        }
        const parts = id.split('&');
        return parts[0]; // Return only the part before the first '&'
    };

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);
    const notify = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyInfo = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const stepChanges = async (prevStep, stepnum) => {
        if (prevStep === 0) {
            if (startMessage === '' || endMessage === '' && status === 'draft') {
                notify('greeting messages cannot be empty please make sure you fill it!!');
                return;
            }
            if (startMessage !== initialStartMessage || endMessage !== initialEndMessage) {
                try {
                    const templateMessages = [];
                    if (startMessage !== initialStartMessage || endMessage !== initialEndMessage) {
                        if (startMessage !== initialStartMessage) {
                            templateMessages.push({ message_type: 'first', message_text: startMessage });
                        } else {
                            templateMessages.push({ message_type: 'first', message_text: initialStartMessage });
                        }

                        if (endMessage !== initialEndMessage) {
                            templateMessages.push({ message_type: 'last', message_text: endMessage });
                        } else {
                            templateMessages.push({ message_type: 'last', message_text: initialEndMessage });
                        }
                    }
                    await ProjectRepository.updateProject({ template_messages: templateMessages }, cleanedProjectId);
                    setInitialStartMessage(startMessage);
                    setInitialEndMessage(endMessage);
                    notifyInfo('Template messages updated');
                    setStep(stepnum);
                } catch (error) {
                    console.error('Failed to update template messages:', error);
                    notify('Failed to update template messages');
                    return; // Stop the step transition on error
                }
            } else {
                setStep(stepnum);
            }

        } else if (prevStep === 1) {
            // Step 2 (previously Step 1): Save questions if they have changed
            if (questions?.length == 0 && status === 'draft') {
                notify('Please make sure questions are added before proceeding!!');
                return;
            }
            if (JSON.stringify(questions) !== JSON.stringify(initialQuestionsData)) {
                try {
                    const questionsData = questions.map(question => ({
                        q_id: question.q_id,
                        q_text: question.q_text,
                        q_type: question.q_type,
                        options: question.options,
                        sequence_no: questions.indexOf(question) + 1
                    }));
                    await ProjectRepository.updateProject({ questions: questionsData }, cleanedProjectId);
                    setInitialQuestionsData(questions);
                    setStep(stepnum);
                } catch (error) {
                    console.error('Failed to update project:', error);
                    notify('Failed to update project');
                    return; // Stop the step transition on error
                }
            } else {
                setStep(stepnum);
            }
        } else if (prevStep === 2) {
            // Step 3 (previously Step 2): Save contacts if they have changed
            if (JSON.stringify(contacts) !== JSON.stringify(initialContactsData)) {
                try {
                    const usersData = contacts.map(contact => ({
                        phone_no: String(contact.phone_no),
                        name: contact.name,
                        is_field_officer: contact.isFieldOfficer === "yes" ? true : false
                    }));
                    await UserRepository.createUsers({ user: usersData }, cleanedProjectId);
                    setInitialContactsData(contacts);
                    setContacts(contacts);
                    setuserFiles([]);
                    setStep(stepnum);
                } catch (error) {
                    console.error('Failed to create user:', error);
                    notify('Failed to create user');
                    return; // Stop the step transition on error
                }
            } else {
                setStep(stepnum);
            }
        } else if (prevStep === 3) {
            setStep(stepnum);
            // Step 4 (previously Step 3): Save languages if they have changed
            // if (JSON.stringify(selectedLanguages) !== JSON.stringify(initialLanguages)) {
            //     try {
            //         const languageValues = selectedLanguages.map(language => language.value);
            //         await ProjectRepository.updateProject({ languages: languageValues }, cleanedProjectId);
            //         setInitialLanguages(selectedLanguages);
            //         setStep(stepnum);
            //     } catch (error) {
            //         console.error('Failed to update project:', error);
            //         notify('Failed to update project');
            //         return; // Stop the step transition on error
            //     }
            // } else {
            //     setStep(stepnum);
            // }
        }
    };



    const checkForChanges = () => {
        if (JSON.stringify(questions) !== JSON.stringify(initialQuestionsData) || JSON.stringify(contacts) !== JSON.stringify(initialContactsData) || JSON.stringify(selectedLanguages) !== JSON.stringify(initialLanguages)) {
            setchangesPresent(true);
        } else {
            setchangesPresent(false);
        }
    }
    const saveChangesNavigate = async () => {
        if (JSON.stringify(questions) !== JSON.stringify(initialQuestionsData)) {
            try {
                const questionsData = questions.map(question => ({
                    q_id: question.q_id,
                    q_text: question.q_text,
                    q_type: question.q_type,
                    options: question.options,
                    sequence_no: questions.indexOf(question) + 1
                }));
                await ProjectRepository.updateProject({ questions: questionsData }, cleanedProjectId);
                setInitialQuestionsData(questions);
            } catch (error) {
                console.error('Failed to update project:', error);
                notify('Failed to update project');
            }
        }

        if (JSON.stringify(contacts) !== JSON.stringify(initialContactsData)) {
            try {
                const usersData = contacts.map(contact => ({
                    phone_no: String(contact.phone_no),
                    name: contact.name,
                }));
                await UserRepository.createUsers({ user: usersData }, cleanedProjectId);
                setInitialContactsData(contacts);
            } catch (error) {
                console.error('Failed to create user:', error);
                notify('Failed to create user');
            }
        }

        if (JSON.stringify(selectedLanguages) !== JSON.stringify(initialLanguages)) {
            try {
                const languageValues = selectedLanguages.map(language => language.value);
                await ProjectRepository.updateProject({ languages: languageValues }, cleanedProjectId);
                setInitialLanguages(selectedLanguages);
            } catch (error) {
                console.error('Failed to update project:', error);
                notify('Failed to update project');
            }
        }
        if (startMessage !== initialStartMessage || endMessage !== initialEndMessage) {
            try {
                const templateMessages = [];
                templateMessages.push({ message_type: 'first', message_text: startMessage });
                templateMessages.push({ message_type: 'last', message_text: endMessage });
                await ProjectRepository.updateProject({ template_messages: templateMessages }, cleanedProjectId);
                setInitialStartMessage(startMessage);
                setInitialEndMessage(endMessage);
            } catch (error) {
                console.error('Failed to update project:', error);
                notify('Failed to update project');
            }
        }
        if (questions?.length === 0) {
            alert('No questions have been uploaded. Please upload questions before proceeding.');
        } else if (startMessage === '' || endMessage === '') {
            alert('Please add a start and end message before proceeding.');
        }
        else {
            navigate('/projects');
        }
    }

    const handleClickNextStep0 = () => {
        stepChanges(0, 1);
    };

    const saveDescription = async () => {
        if (desc !== '' && desc !== initialDesc) {
            try {
                await ProjectRepository.updateProject({ description: desc }, cleanedProjectId);
                setDesc(desc);
                setinitialDesc(desc);
            } catch (error) {
                console.error('Failed to update description:', error);
                notify('Failed to update description');
                setDesc(initialDesc);
            }
        } else {
            notify('Description cannot be empty');
            setDesc(initialDesc);
        }
    };

    const handleDownload = (data) => {
        const ws = XLSX.utils.json_to_sheet(data.map(item => ({
            'Name': item.name,
            'Phone Number': item.phone_no,
            'Is Field Officer': item.is_field_officer ? 'Yes' : 'No',
            'Project ID': item.project_id
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Contacts");

        // Create file name with current date
        const date = new Date();
        const fileName = `Contacts_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.xlsx`;

        // Write the Excel file
        XLSX.writeFile(wb, fileName);
    };

    const removeContactsById = (id) => {
        setContacts(contacts.filter(contact => contact.sourceFileId !== id));
        setuserFiles(userFiles.filter(file => file.id !== id));
    };

    const handleFileChange = (e) => {
        if (!e.target.files[0]) {
            alert('No file chosen. Please choose a file.');
            return;
        }
        const file = e.target.files[0];
        setFile(file);
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

            if (data?.length > 0 && data[0]?.length >= 3 &&
                data[0][0]?.toLowerCase() === 'questions' &&
                data[0][1]?.toLowerCase() === 'questionstype' &&
                data[0][2]?.toLowerCase() === 'choices' &&
                data[0][3]?.toLowerCase() === 'can response be in voice message?') {
                setError(null);
                const filteredData = data.slice(1).filter(row => row[0] && row[1]);

                if (filteredData?.length > max_questions) {
                    // console.log(`More than ${max_questions} questions detected. Only the first ${max_questions} will be processed.`);
                    notify(`More than ${max_questions} questions detected. Only the first ${max_questions} will be processed.`);
                }

                // const limitedData = filteredData.slice(0, max_questions);  // Process only the first max_questions questions

                let tooLongOption = false;
                const limitedData = filteredData.slice(0, max_questions).filter(row => {
                    const options = row[2] ? row[2].split(';') : [];
                    const hasLongOption = options.some(option => option?.length > 24);
                    if (hasLongOption) {
                        notify(`Excluding question: '${row[0]}' as it contains an option exceeding 24 characters.`);
                        tooLongOption = true;
                    }
                    return !hasLongOption;
                });

                if (tooLongOption) {
                    setError("One or more questions have been excluded due to options exceeding 24 characters.");
                }

                // const newQuestions = limitedData.map(row => ({
                //     q_id: uuidv4(),
                //     q_text: row[0],
                //     q_type: row[1],
                //     options: row[2] ? row[2].split(';') : []
                // }));
                const newQuestions = limitedData.map(row => {
                    // Destructure the row to get the required values
                    const [q_text, q_type, optionsString, audioResponse] = row;

                    let modifiedQText = q_text;
                    if (audioResponse.toLowerCase() === 'yes') {
                        modifiedQText += " 🗣️";
                    }
                    if (q_type === 'Location') {
                        modifiedQText += "📍";
                    }
                    // Create the base question object
                    const question = {
                        q_id: uuidv4(),
                        q_text: modifiedQText,
                        q_type: q_type
                    };

                    // Add the 'options' property only if 'q_type' is 'Multiple Choice'
                    if (q_type === 'Multiple Choice' && optionsString) {
                        question.options = optionsString.split(';');
                    }
                    

                    return question;
                });

                setQuestions(newQuestions);
            } else {
                // setError("The first three cells must be named 'questions', 'questionstype', and 'options'.");
                notify("The first four cells must be named 'questions', 'questionstype', 'choices' and 'Can response be in voice message?'.");
            }
        };
        reader.readAsBinaryString(file);
        // Reset the input value
        e.target.value = null;
    }

    const handleUserFileChange = (e) => {
        if (!e.target.files[0]) {
            notify('No file chosen. Please choose a file.');
            return;
        }
        const file = e.target.files[0];
        setContactsFile(file);
        const reader = new FileReader();
        const fileId = uuidv4();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            if (data?.length > 1) {
                const headers = data[0];
                const nameIndex = headers.indexOf('name');
                const phoneNoIndex = headers.indexOf('phoneNo');
                const isFieldOfficerIndex = headers.indexOf('isFieldOfficer');
                if (nameIndex !== -1 && phoneNoIndex !== -1 && isFieldOfficerIndex !== -1) {
                    setError(null);
                    const newContacts = data.slice(1)
                        .filter(row => row[nameIndex] && row[phoneNoIndex])
                        .map(row => ({
                            id: uuidv4(),
                            name: row[nameIndex] || '',
                            phone_no: row[phoneNoIndex] || '',
                            isFieldOfficer: row[isFieldOfficerIndex] || '',
                            sourceFileId: fileId
                        }));

                    // Remove duplicates from new contacts themselves
                    const uniqueNewContacts = _.uniqBy(newContacts, 'phone_no');
                    const uniqueContactsToAdd = _.differenceBy(uniqueNewContacts, contacts, 'phone_no');
                    if (!contacts) {
                        if (uniqueNewContacts?.length <= max_users) {
                            setContacts(uniqueNewContacts);
                            notifyInfo(`${uniqueNewContacts?.length} new contacts added successfully.`);
                        } else {
                            setContacts(uniqueNewContacts.slice(0, max_users));
                            // console.error(`Contact limit exceeded: Cannot add more than ${max_users} contacts. Some contacts have not been added.`);
                            notify(`Contact limit exceeded: Cannot add more than ${max_users} contacts. Some contacts have not been added.`);
                        }
                    } else {
                        setContacts(prevContacts => {
                            const combinedContacts = [...prevContacts, ...uniqueNewContacts];
                            const finalContacts = _.uniqBy(combinedContacts, 'phone_no');
                            const contactsToAdd = [];
                            notifyInfo(`${finalContacts?.length} unique contacts processed after merging with existing.`);
                            // Add contacts only if under the limit, log an error for each over the limit
                            finalContacts.forEach(contact => {
                                if (contactsToAdd?.length < max_users) {
                                    contactsToAdd.push(contact);
                                } else {
                                    // console.error(`Contact limit exceeded: Cannot add more than ${max_users} contacts. Contact with phone number ${contact.phone_no} not added.`);
                                    notify(`Contact limit exceeded: Cannot add more than ${max_users} contacts. Contact with phone number ${contact.phone_no} not added.`);
                                }
                            });

                            return contactsToAdd;
                        });
                    }
                    if (uniqueContactsToAdd?.length > 0) {
                        setuserFiles(prevUserFiles => {
                            const newFile = {
                                id: fileId,
                                name: file.name,
                                contactsCount: uniqueContactsToAdd?.length
                            };
                            return [...prevUserFiles, newFile];
                        });
                        notifyInfo(`${uniqueContactsToAdd?.length} new unique contacts identified and associated with file ${file.name}.`);
                    }
                } else {
                    // setError("Columns 'name', 'phoneNo', and 'isFieldOfficer' are required.");
                    notify("Columns 'name', 'phoneNo', and 'isFieldOfficer' are required.");
                }
            } else {
                // setError("The file is empty or does not contain the required columns.");
                notify("The file is empty or does not contain the required columns.");
            }
        };
        reader.readAsBinaryString(file);
        // Reset the input value
        e.target.value = null;
    };


    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(questions);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setQuestions(items);
    };

    const deleteQuestion = (id) => {
        setQuestions(questions.filter(question => question.q_id !== id));
    };

    const deleteContact = (id) => {
        setContacts(contacts.filter(contact => contact.id !== id));
    };
    if (isLoading) {
        return <div className='textcolor'>Loading...</div>; // Show loading state until questions are ready
    }
    return (
        <>
            {isPageLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <CSpinner className='spinner'/>
                </div>
            ) : (<div>
                <CCard className='ccard ccard'>
                    <CCardHeader className='view-data-header'>
                        <div >
                            {name}
                        </div>
                    </CCardHeader>
                    <CCardBody>
                        <div>
                            <ToastContainer />
                        </div>
                        {/* <div style={{ fontSize: '20px' }}>
                        {desc}
                    </div> */}
                        <div className='flexStart' style={{ fontSize: '20px' }}>
                            <textarea
                                ref={textAreaRef}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                                className='descInput'
                                placeholder="Enter description here..."
                                style={{ minWidth: '20%', width: '90%', marginBottom: '2rem', maxHeight: '10rem', resize: 'none' }}
                            />
                            {/* Hidden span to measure text width */}
                            <span ref={spanRef} style={{ position: 'absolute', visibility: 'hidden', height: 'auto', width: 'auto', whiteSpace: 'nowrap', fontSize: '20px' }}>
                                {desc}
                            </span>
                            {desc !== initialDesc && (
                                <CIcon onClick={saveDescription} icon={cilSave} size="xl" style={{ cursor: "pointer" }} />
                            )}
                        </div>
                        {/* {error && <p className="error-message">{error}</p>} */}
                        <div className="container">
                            <ul className="progressbar">
                                <li onClick={() => { stepChanges(step, 0) }} className={`card-header-step step ${step >= 0 ? 'active' : ''}`}>
                                    <div className='mainNavDiv'>
                                        <img style={{ width: '2rem' }} src={messages} />
                                        {/* <div className='numDiv'>
                                        📧questions
                                    </div> */}
                                        <div className="flexCenter">
                                            Configure Messages
                                        </div>
                                    </div>
                                </li>
                                <li onClick={() => { stepChanges(step, 1) }} className={`card-header-step step ${step >= 1 ? 'active' : ''}`}>
                                    <div className='mainNavDiv'>
                                        <img style={{ width: '2rem' }} src={questionsIcon} />
                                        {/* <div className='numDiv'>
                                        📝
                                    </div> */}
                                        <div>
                                            Upload Questions
                                        </div>
                                    </div>
                                </li>
                                {/* <li onClick={() => { stepChanges(step, 2) }} className={`card-header-step step ${step >= 2 ? 'active' : ''}`}>
                                <div className='mainNavDiv'>
                                    <div className='numDiv'>
                                        👤
                                    </div>
                                    <div>
                                        Upload Users
                                    </div>
                                </div>
                            </li> */}
                                <li onClick={() => { stepChanges(step, 3) }} className={`card-header-step step ${step >= 3 ? 'active' : ''}`}>
                                    <div className='mainNavDiv'>
                                        <img style={{ width: '2rem' }} src={languageIcon} />
                                        {/* <div className='numDiv'>
                                        🌐languageIcon
                                    </div> */}
                                        <div>
                                            Select Language
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </CCardBody>
                </CCard>
                {step == 0 && <>
                    <CCard className="custom-card ccard">
                        <CCardBody>
                            <CAccordion>
                                <CAccordionItem itemKey={1}>
                                    <CAccordionHeader >Quick Guide for adding configuration message</CAccordionHeader>
                                    <CAccordionBody style={{marginLeft:'2rem'}}>
                                        <ol className="guide-list">
                                            <li>
                                                <strong>Add Initial Greeting Message</strong> - Enter the initial greeting message that you want users to see when they first start a conversation on WhatsApp.
                                            </li>
                                            <li>
                                                <strong>Add Closure Message</strong> - Enter the closure message that users will recieve once they answer all the questions on Whatsapp.
                                            </li>
                                            <li>
                                                <strong>Save Your Changes</strong> - After configuring the initial greeting message and closure message, click the "Next" button to apply your changes.
                                            </li>
                                        </ol>
                                    </CAccordionBody>
                                </CAccordionItem>
                            </CAccordion>
                        </CCardBody>
                    </CCard>

                    <CCard className="custom-card ccard">
                        <CCardHeader className='greeting-sec-header'>
                            Customize Your Greeting and Closure Messages
                        </CCardHeader>
                        <CCardBody>
                            <div className="mb-3">
                                <h6>*Greeting Message:</h6>
                                <textarea placeholder='Eg: Welcome! We appreciate your participation in this survey. Your insights will help us improve our services.' value={startMessage} onChange={(e) => setStartMessage(e.target.value)} className="form-control" rows="2" disabled={status !== 'draft'}></textarea>
                            </div>
                            <div className="mb-3">
                                <h6>*Closure Message:</h6>
                                <textarea placeholder='Eg: Thank you for completing our survey! Your feedback is crucial to our efforts to improve.' value={endMessage} onChange={(e) => setEndMessage(e.target.value)} className="form-control" rows="2" disabled={status !== 'draft'}></textarea>
                            </div>
                        </CCardBody>
                    </CCard>
                    <CButton onClick={handleClickNextStep0} style={{ marginTop: '1rem', marginBottom: '14px' }} className="custom-file-input">
                        Next
                    </CButton>
                </>}
                {step === 1 && (
                    status === 'draft' ? (
                        <Step1
                            nextStep={nextStep}
                            questions={questions}
                            onFileChange={handleFileChange}
                            onDragEnd={handleDragEnd}
                            onDeleteQuestion={deleteQuestion}
                            file={file}
                            projectId={cleanedProjectId}
                            stepChanges={stepChanges}
                        />
                    ) : (
                        <Step1Static
                            nextStep={nextStep}
                            questions={questions}
                            file={file}
                            projectId={cleanedProjectId}
                            stepChanges={stepChanges}
                            noteVisible={true}
                        />
                    )
                )}
                {/* {step === 2 && <Step2 nextStep={nextStep} prevStep={prevStep} onFileChange={handleUserFileChange} contacts={contacts} contactsFile={contactsFile} onDeleteContact={deleteContact} projectId={cleanedProjectId} stepChanges={stepChanges} userFiles={userFiles} removeContactsById={removeContactsById} handleDownload={handleDownload} />} */}
                {step === 3 && <Step3 selectedLanguages={selectedLanguages} setSelectedLanguages={setSelectedLanguages} prevStep={prevStep} projectId={cleanedProjectId} saveChangesNavigate={saveChangesNavigate} stepChanges={stepChanges} initialLanguages={initialLanguages} />}
            </div >)}
        </>
    );
};

export default WizardComponent;