import { assesmentDetailsEndPoints } from "./endPoints";
import { toast } from "react-toastify";
import { IaApiClient } from "src/network/iaApiClient";

class AssesmentRepository {
    createAssessment = async (body) => {
        let data = await IaApiClient.post(assesmentDetailsEndPoints.createAssessment(), body).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while creating assessment");
        });
        return data;
    }

    getAssessmentDetails = async (owner) => {
        let data = await IaApiClient.get(assesmentDetailsEndPoints.getAssessmentDetails(owner)).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while retrieving assessment details");
        });
        return data;
    }

    getAssessmentDetailsById = async (assessmentId) => {
        let data = await IaApiClient.get(assesmentDetailsEndPoints.getAssessmentDetailsById(assessmentId)).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while retrieving assessment details by ID");
        });
        return data;
    }

    getUserResponsesById = async (projectId) => {
        let data = await IaApiClient.get(assesmentDetailsEndPoints.getUserResponsesById(projectId)).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while retrieving non-verified responses");
        });
        return data;
    }

    createCurateResponse = async (projectId, body) => {
        let data = await IaApiClient.post(assesmentDetailsEndPoints.createCurateResponse(projectId), body).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while creating verified response");
        });
        return data;
    }

    getCuratedResponses = async (projectId) => {
        let data = await IaApiClient.get(assesmentDetailsEndPoints.getCuratedResponses(projectId)).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while retrieving verified responses");
        });
        return data;
    }

    createDiscardedResponse = async (projectId, body) => {
        let data = await IaApiClient.post(assesmentDetailsEndPoints.createDiscardedResponse(projectId), body).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while creating rejected response");
        });
        return data;
    }

    getDiscardedResponses = async (projectId) => {
        let data = await IaApiClient.get(assesmentDetailsEndPoints.getDiscardedResponses(projectId)).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while retrieving rejected responses");
        });
        return data;
    }

    updateAssessmentDetails = async (asssessmentId, body) => {
        let data = await IaApiClient.post(assesmentDetailsEndPoints.updateAssessmentDetails(asssessmentId), body).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while updating assessments");
        });
        return data;
    }

    uploadFiles = async (formData) => {
        let data = await IaApiClient.upload(assesmentDetailsEndPoints.uploadFiles(formData)).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while uploading files");
        });
        return data;
    }

    deleteFiles = async (keys) => {
        let data = await IaApiClient.post(assesmentDetailsEndPoints.deleteFiles(),{keys}).catch((err) => {
            console.log("Error", err);
            toast.error("Something went wrong while deleting files");
        });
        return data;
    }
}

const assesmentRepository = new AssesmentRepository();
export { assesmentRepository as AssesmentRepository };
