import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilSpeedometer, cilBook
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Projects',
    to: '/projects', // Specify the correct route
    icon: <CIcon icon={cilBook} customClassName="nav-icon" />,
    // Optional: Add a badge if needed
    // badge: {
    //   color: 'info', // Or any other color
    // },
  },
  {
    component: CNavItem,
    name: 'Assessments',
    to: '/assessments',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: 'info',
    },
  },
]

export default _nav
