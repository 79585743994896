import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CCard, CCardBody, CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';
import { useEffect } from 'react';
import _ from 'lodash';
import { max_questions } from './exportData';
import emptyIllustration from '../../assets/images/No data-cuate.svg'


export const Step1Static = ({ nextStep, questions, onFileChange, onDragEnd, onDeleteQuestion, file, projectId, stepChanges, noteVisible, nextPrevVisible = true }) => {
    // const localId = projectId;
    const [initialQuestionsData, setInitialQuestionsData] = useState([]);
    useEffect(() => {
        // Save the initial questions data when component mounts
        setInitialQuestionsData(questions || []);
    }, []);
    const handleClickNext = () => {
        stepChanges(1, 3);
    };
    const handleClickPrev = () => {
        stepChanges(1, 0);
    };


    return (
        <div>
            {noteVisible && (
                <CCard className="custom-card ccard">
                    <CCardBody>
                        <>
                            <h5 className="card-header">Quick Guide for Uploading Questions</h5>
                            <div className="note-div">
                                <ol className="guide-list">
                                    <li><strong>Please use only English text in the documents</strong></li>
                                    <li><strong>Download the Template:</strong> Click "Download Template" to save the required file. Make sure you get the right version for your needs.</li>
                                    <li><strong>Fill Out the Template:</strong> Open the file with the appropriate software and fill in all fields accurately. Save your changes.</li>
                                    <li><strong>Upload Completed File:</strong> Return to this page, click "Choose File", and select the completed document to upload.</li>
                                    <li><strong>Descriptive options:</strong> These options indicate the question can be audio or text.</li>
                                    <li><strong>Max questions allowed:</strong> {max_questions}</li>
                                    <li>
                                        <strong>Modify Questions:</strong> If you need to make changes after uploading, you can do so by following the instructions provided.
                                        <br /><br />
                                        <ol className='nested'>
                                            <li><strong>Delete Questions:</strong> If you want to delete any questions, click the delete icon present in the right corner of the question.</li>
                                            <li><strong>Reorder Questions:</strong> Drag and drop the questions tile to reorder.</li>
                                            <li><strong>Add New Question:</strong> If you have to add a new question, please make the required changes in the file you have uploaded and reupload the updated file.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                            {/* <div className='fileDiv'>
                                <CButton href={questionsTemplate} download="questions_template.xlsx" color="primary" className="custom-file-input labelBtn">
                                    <CIcon icon={cilCloudDownload} customClassName="tempIcon" />
                                    Download Template
                                </CButton>
                            </div> */}
                        </>

                    </CCardBody>
                </CCard>
            )}


            {/* <CButton href="/path/to/template/file.xlsx" download="Template.xlsx" color="primary">Download Template</CButton> */}
            {questions?.length === 0 ? (
                <>
                    <CCard style={{ marginTop: '1rem', marginBottom: '1rem' }} className='ccard'>
                        <CCardBody>
                            <label htmlFor="fileInput" style={{ cursor: 'pointer', textAlign: 'center', display: 'block' }}>
                                <div className="empty-placeholder" style={{ padding: '2rem', border: '2px dashed #ccc', borderRadius: '8px' }}>
                                    <img src={emptyIllustration} alt="Select File" style={{ width: '20rem', height: '14rem', marginBottom: '1rem' }} />
                                    <h5 style={{ marginBottom: '1rem' }}>No Questions Added</h5>
                                    {/* <h4 style={{ marginBottom: '1rem' }}>Please click here to choose a file</h4>
                                    <p style={{ color: '#777' }}>Please choose a file for questions to appear here.</p> */}
                                </div>
                            </label>
                        </CCardBody>
                    </CCard>
                </>
                // <div className="empty-placeholder">
                //     {/* <label htmlFor="fileInput"> */}
                //     <label>
                //         <img src={emptyPlaceholder} alt="Empty placeholder" />
                //     </label>
                //     {/* <span>Please add a file</span> */}
                // </div>
            ) : (
                <CCard style={{ marginTop: '1rem', marginBottom: '1rem' }} className='ccard'>
                    <div className='step1NumDiv'>
                        <div className='one-gap'>
                            <div className='flexRow'><div className='total-no-questions'>Total number of questions:</div><span className="slNumbers">{questions?.length}</span></div>
                            {/* <div className='flexRow'><h5>Total number of multiple questions:</h5><span className="slNumbers">{questions.filter(question => question.q_type === 'Multiple Choice')?.length}</span></div> */}
                        </div>
                    </div>
                    <CCardBody>
                        <ul className="questions-list">
                            {questions.map((question, index) => (
                                <li key={question.q_id} className="question-item">
                                    <div className="quesDiv">
                                        <span className="slNumbers">{`${index + 1}`}</span>
                                        <span className="question-order">{question.q_text}</span>
                                        {question.q_type === 'Descriptive' && <div className='nameTag userTag'>{question.q_type}</div>}
                                        {question.q_type === 'Multiple Choice' &&
                                            <CDropdown>
                                                <CDropdownToggle className='userTag multiple-choice' color='dark'>Multiple Choice <span className='multipleDrop'>▼</span></CDropdownToggle>
                                                <CDropdownMenu>
                                                    {question.options.map((option, index) => (
                                                        <CDropdownItem className='flexStart' key={index}>{option}</CDropdownItem>
                                                    ))}
                                                </CDropdownMenu>
                                            </CDropdown>
                                        }
                                        {question.q_type === 'Location' && <div className='nameTag userTag'>{question.q_type}</div>}
                                    </div>
                                    {/* Uncomment and modify the below lines as needed for delete functionality */}
                                    {/* <button onClick={() => onDeleteQuestion(question.q_id)} className="delete-button">X</button> */}
                                    {/* <img onClick={() => onDeleteQuestion(question.q_id)} style={{ height: '1.5rem', width: '1.5rem' }} src={trashIcon} /> */}
                                </li>
                            ))}
                        </ul>
                    </CCardBody>

                </CCard>
            )}
            {nextPrevVisible &&
                <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }}>
                    <CButton color="secondary" onClick={handleClickPrev}>Previous</CButton>
                    <CButton color="primary" onClick={handleClickNext}>Next</CButton>
                </div>
            }
        </div>
    );
};

Step1Static.propTypes = {
    questions: PropTypes.array,
    onFileChange: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDeleteQuestion: PropTypes.func,
    file: PropTypes.object,
    projectId: PropTypes.string,
    stepChanges: PropTypes.func,
};


