import React, { useEffect, useRef } from 'react';
import { CCol, CCard, CCardHeader, CCardBody, CRow } from '@coreui/react';
import { ResponsesRepository } from 'src/repositories/responseRepository';
import { MarkerClusterer } from '@googlemaps/markerclusterer'; 

const LocationQuestionStats = ({ projectId, q_id, sequence_no, q_text, answeredCount }) => {
  const mapRef = useRef(null);

  const getResponses = async () => {
    try {
      const response = await ResponsesRepository.getResponseDetails(projectId, q_id);
      const responseData = response.data;
      const locations = responseData
        .map(item => {
          try {
            const parsedText = JSON.parse(item.response_text);
            if (parsedText.latitude && parsedText.longitude) {
              return {
                latitude: parsedText.latitude,
                longitude: parsedText.longitude,
                name: parsedText.name || 'Unknown location',
                address: parsedText.address || '',
              };
            }
            return null;
          } catch (error) {
            console.error('Failed to parse response_text', error);
            return null;
          }
        })
        .filter(location => location !== null);

      initializeMap(locations);
    } catch (error) {
      console.error('Failed to fetch questions data', error);
    }
  };

  const initializeMap = (locations) => {
    const map = new google.maps.Map(mapRef.current, {
      center: locations.length > 0 ? 
        { lat: locations[0].latitude, lng: locations[0].longitude } : 
        { lat: 12.960069283652, lng: 77.511125773246 },
      zoom: 2,
    });

    const markers = locations.map(location => {
      const marker = new google.maps.Marker({
        position: { lat: location.latitude, lng: location.longitude },
        title: location.name,
      });

      const infoWindow = new google.maps.InfoWindow({
        content: `<div><strong>${location.name}</strong><br>${location.address}</div>`,
      });

      marker.addListener('click', () => {
        infoWindow.open(map, marker);
      });

      return marker;
    });

    new MarkerClusterer({ markers, map });
  };

  useEffect(() => {
    getResponses();
  }, []);

  return (
    <CCol xs="12" md="10" className="mb-4">
      <CCard className="shadow-sm qsummary-card">
        <CCardHeader>
          <h5 className="m-0">Question {sequence_no}</h5>
        </CCardHeader>
        <CCardBody className=" p-3">
          <CRow className="mb-3">
            <CCol>
              <p>{q_text}</p>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="3" className='rowDiv'>
              <div className="p-2">
                <h6 className="text-center mb-1 flexCol">Answered: {answeredCount}</h6>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol xs="12">
              <div ref={mapRef} style={{ height: '400px', width: '100%' }}></div>  
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default LocationQuestionStats;
