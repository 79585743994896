import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  CContainer,
  CRow,
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CCardHeader
} from '@coreui/react';

import Summary from './summary';
import QuestionSummaries from './qsummary';
var cleanedProjectId;
var cleanedName;


const Dashboard = () => {
  const { projectId, projectName } = useParams();
  const [ dashboard, setDashboard ] = useState(false);
  const [activeTab, setActiveTab] = useState('summary');

  useEffect(() => {
    const cleanId = cleanProjectId(projectId);
    const cleanName = cleanProjectId(projectName);
    cleanedProjectId = cleanId;
    cleanedName = cleanName;
    if(cleanedProjectId && cleanedName){
      setDashboard(true);
    }
    // renderContent();
  }, [projectId]);


  useEffect(() => {
    renderContent();
  }, [cleanedName]);

  const cleanProjectId = (id) => {
    if (!id.includes('&')) {
      return id;
    }
    const parts = id.split('&');
    return parts[0]; // Return only the part before the first '&'
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'summary':
        return <Summary projectId={cleanedProjectId} />;
      case 'questionSummaries':
        return <QuestionSummaries projectId={cleanedProjectId} />;
      default:
        return null; // Return null if no tab is selected
    }
  };

  return (
    <CContainer>
      <div className="container">
        <CCardHeader>
          <div className='h2 question-summary-header'>{cleanedName}</div>
        </CCardHeader>
        <CNav variant="tabs" className="sub-tabs">
          <CNavItem>
            <CNavLink
              active={activeTab === 'summary'}
              onClick={() => setActiveTab('summary')}
              className={`pointer ${activeTab === 'summary' ? 'active-tab' : ''}`}
            >
              Summary
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink
              active={activeTab === 'questionSummaries'}
              onClick={() => setActiveTab('questionSummaries')}
              className={`pointer ${activeTab === 'questionSummaries' ? 'active-tab' : ''}`}
            >
              Question Summaries
            </CNavLink>
          </CNavItem>
        </CNav>
        <CRow className="mt-4">
          {activeTab ? (
            <CCol>
              {renderContent()}
            </CCol>
          ) : null}
        </CRow>
      </div>
    </CContainer>
  );
};

export default Dashboard;
