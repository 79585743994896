import React, { useState, useEffect } from 'react';
import {
  CCard,
  CCardBody,
  CCardHeader,
  CRow,
  CCol,
  CWidgetStatsA,
  CAlert,
  CBadge,
} from '@coreui/react';
import { Bar } from 'react-chartjs-2';
import CIcon from '@coreui/icons-react';
import { cilCheckCircle } from '@coreui/icons';
import PropTypes from 'prop-types';
import { ProjectRepository } from 'src/repositories/projectRepository';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { standardizeDateFormat } from './utils';

const options = {
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Count', // Label for the y-axis
        fontSize: 18,
        color: '#d9d9d9'
      },
      ticks: {
        color: '#d9d9d9', // Color of the x-axis labels
      },
      grid: {
        color: '#ffffff44', // Color of the x-axis grid lines
      },
    },
    x: {
      title: {
        display: true,
        text: 'Date', // Label for the x-axis
        fontSize: 18,
        color: '#d9d9d9'

      },
      grid: {
        color: '#ffffff44', // Color of the x-axis grid lines
      },
      ticks: {
        color: '#d9d9d9', // Color of the x-axis labels
      },
    },
  },

  plugins: {
    legend: {
      labels: {
        color: "#d9d9d9",
      },
    },
  },
};

const Summary = ({ projectId }) => {
  const [projectSummary, setProjectSummary] = useState([]);

  useEffect(() => {
    if (projectId) {
      fetchProjectSummary();
      // const intervalId = setInterval(fetchProjectSummary, 1000);
      // return () => clearInterval(intervalId);
    }
  }, [projectId]);

  useEffect(() => {
    setProjectSummary(null);
    return () => {
      setProjectSummary(null);
    };
  }, []);

  const fetchProjectSummary = async () => {
    try {
      const response = await ProjectRepository.getProjectSummary(projectId);
      setProjectSummary(response);
    } catch (error) {
      console.error('Failed to fetch project data', error);
    }
  };

  if (!projectSummary) {
    return <div>Loading...</div>;
  }

  function formatGraphData(data) {
    const chartData = {
      labels: data.map(entry => {
        const date = new Date(entry.date);
        const formattedDate = `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}`;
        return formattedDate;  // Returns 'Apr 4', 'May 15', etc.
      }),
      datasets: [
        {
          label: 'Submissions',
          data: data.map(entry => entry.unique_sessions),
          backgroundColor: '#9fffac',
          // borderWidth: 1,
        },
      ],
    };
    return chartData;
  }

  function getOldestAndNewestDates(responses) {
    if (!responses) return { oldest: null, newest: null };
    if (responses?.length === 0) return { oldest: null, newest: null };

    let oldest = new Date(responses[0].date);
    let newest = new Date(responses[0].date);

    responses.forEach(response => {
      const currentDate = new Date(response.date);
      if (currentDate < oldest) oldest = currentDate;
      if (currentDate > newest) newest = currentDate;
    });

    return {
      oldest: standardizeDateFormat(oldest),
      newest: standardizeDateFormat(newest)
    };
  }


  const { name,
    createdOn,
    questionsCount,
    status,
    languages,
    totalUsers,
    percentageSessionsWithAllResponses,
    usersFullyRespondedCount,
    usersWithIncompleteResponsesCount,
    allResponses } = projectSummary;

  const isLoading = [
    createdOn,
    percentageSessionsWithAllResponses,
    usersFullyRespondedCount,
    usersWithIncompleteResponsesCount,
    // languages,
    questionsCount,
    totalUsers,
    status,
    allResponses
  ].some(value => value === null || value === undefined);
  const dates = getOldestAndNewestDates(allResponses);

  return (
    <div>
      {isLoading ? (
        <CCard className='summaryCards'>
          <CCardBody>
            <CAlert color="warning">No responses to display</CAlert>
          </CCardBody>
        </CCard>
      ) : (
        <>
          <CRow>
            <div className='dateDiv mb-2'>Created at: {standardizeDateFormat(createdOn)}</div>
            <CCol md="3" style={{ padding: '0px 0px 0px 12px' }}>
              <CCard style={{ height: '42.6rem' }} className='summaryCards'>
                <CCardBody>
                  <CRow md="5">
                    <CircularProgressbar
                      value={percentageSessionsWithAllResponses}
                      text={`${percentageSessionsWithAllResponses}%`}
                      styles={buildStyles({
                        pathColor: '#9fffac',
                        textColor: 'var(--primary)'
                      })}
                    />
                    <div className='font18 flexCenter mt-1 mb-1'>People attended all questions</div>
                  </CRow>
                  <CRow md="5" className='mb-3'>
                    <hr />
                    <div className='font18'> <strong>Completed users:</strong> {usersFullyRespondedCount}</div>
                    <div className='font18'><strong>Incompleted users:</strong> {usersWithIncompleteResponsesCount}</div>
                  </CRow>
                  <CRow md="5">
                    <hr />
                    <div className='font18 flex'><strong>Languages</strong></div>
                    <div className='summaryLangDiv'>
                      {languages?.map((language, index) => {
                        const capitalizedLanguage = language.charAt(0).toUpperCase() + language.slice(1);
                        return (
                          <CBadge key={index}   className="mr-1 mb-2 fs-1rem summary-badge">
                            {capitalizedLanguage}
                          </CBadge>
                        );
                      })}
                    </div>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol md="9" style={{ height: '70vh' }}>
              <CRow style={{ height: '30%' }}>
                <CCol md="4" style={{ paddingRight: '0' }}>
                  <CCard style={{ height: '10rem', paddingRight: '0' }} className='summaryCards'>
                    <CCardBody>
                      <div className='flexColumn gap0'>
                        <div className='h5 flexCenter mt-1 textcolor'>Questions</div>
                        <div className='h2 flexCenter mt-1 '>
                            {questionsCount}
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol md="4" style={{ paddingRight: '0' }}>
                  <CCard style={{ height: '10rem', paddingRight: '0' }} className='summaryCards'>
                    <CCardBody>
                      <div className='flexColumn gap0'>
                        <div className='h5 flexCenter mt-1 textcolor' >Submissions</div>
                        <div className='h2 flexCenter mt-1'>
                          <div color="warning" >
                            {totalUsers}
                          </div>
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCol>
                <CCol md="4">
                  <CCard style={{ height: '10rem', paddingRight: '0' }} className='summaryCards'>
                    <CCardBody>
                      <div className='flexColumn gap0'>
                        <div className='h5 flexCenter mt-1 textcolor'>Status</div>
                        <div className='h2 flexCenter mt-1'>
                            {status}
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
              <CRow className='graphRow'>
                <CCard className='summaryCards'>
                  <CCardBody>
                    <div className='subDiv'>
                      <div className='h5 flexStart mt-1'>Submissions by day</div>
                      <div className='h6 flexStart mt-1'>
                        <div>First: {dates.oldest}</div>
                        <div>Last: {dates.newest}</div>
                      </div>
                    </div>
                    {allResponses && (
                      <div style={{ height: '23rem' }}>
                        <Bar data={formatGraphData(allResponses)} options={options} />
                      </div>
                    )}
                  </CCardBody>
                </CCard>
              </CRow>
            </CCol>
          </CRow>
        </>
      )}
    </div>
  );
};

Summary.propTypes = {
  projectId: PropTypes.string,
};

export default Summary;