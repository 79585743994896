import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CContainer,
  CBadge,
  CSpinner,
  CTooltip,
  CListGroupItem
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import { GrStatusGoodSmall } from "react-icons/gr";
import { Link } from 'react-router-dom';
import { cilCloudDownload, cilCloudUpload } from '@coreui/icons';
import { cibAdobe, cibMicrosoft, cilFile } from '@coreui/icons';
import {
  CCardTitle, CCardText, CModal, CModalHeader,
  CModalTitle, CModalBody, CModalFooter, CDropdownToggle, CDropdown, CDropdownItem, CDropdownMenu, CFormInput, CFormTextarea
} from '@coreui/react'
import './assessment.css'
import { useNavigate } from 'react-router-dom';
import { useKeycloak } from "@react-keycloak/web";
import { ProjectRepository } from 'src/repositories/projectRepository';
import { AssesmentRepository } from "src/repositories/assessmentRepository";
import trashIcon from 'src/assets/images/trash-can-regular-red.svg';
import { IaApiClient } from "src/network/iaApiClient";
import { CONFIG } from "src/config/envConstants";
import asessmentTemplate from 'src/assets/temp/Assessment.txt';
import { ToastContainer, toast } from 'react-toastify';

const Assessment = () => {
  const [createModal, setCreateModal] = useState(false);
  const { keycloak } = useKeycloak();
  const [projectData, setProjectData] = useState([]);
  const [assessmentName, setAssessmentName] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  // setProjectData(projectDummyData);        
  const [assessmentsCards, setAssessmentsCards] = useState([]);
  const [selectedProject, setSelectedProject] = useState('Please select project');
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    getAssessmentDetails();
  }, []);

  const navigate = useNavigate();

  const getFileIcon = (fileExtension) => {
    switch (fileExtension?.toLowerCase()) {
      case 'pdf':
        return cibAdobe;
      case 'doc':
      case 'docx':
        return cibMicrosoft;
      case 'txt':
        return cilFile;
      default:
        return cilFile;
    }
  };

  const getAssessmentDetails = async () => {
    try {
      const data = await AssesmentRepository.getAssessmentDetails(keycloak.tokenParsed.preferred_username)
      if (data) {
        console.log(data);
        setAssessmentsCards(data);
      }
    } catch {
      console.error('some error in fetching assessment data');
    }
  }

  const notify = (message) => toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
  const notifyInfo = (message) => toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });

  const isValid = assessmentName.trim() !== '' && description.trim() !== '' && files && files?.length > 0 && files.every(file => file.status === 'uploaded');

  const handleFileChange = async (e) => {
    const maxFiles = 5; // Maximum number of files allowed
    const maxFileSize = 10 * 1024 * 1024; // 10 MB in bytes

    // Filter and validate selected files based on type and size
    const selectedFiles = Array.from(e.target.files).filter(file => {
      const isValidType = ['application/msword', 'text/plain', 'application/pdf'].includes(file.type);
      const isValidSize = file.size <= maxFileSize;

      if (!isValidSize) {
        console.error(`File ${file.name} exceeds the 10 MB size limit.`);
        notify(`File ${file.name} exceeds the 10 MB size limit.`);
      }

      return isValidType && isValidSize;
    });

    const totalFiles = files?.length + selectedFiles?.length;

    if (totalFiles > maxFiles) {
      console.error(`You can only upload up to ${maxFiles} files.`);
      notify(`You can only upload up to ${maxFiles} files.`);
      return;
    }

    // Limit the files to the maximum allowed and prepare them for uploading
    const limitedFiles = selectedFiles.slice(0, maxFiles - files?.length).map(file => ({
      file,
      status: 'pending',
      url: '',
      key: '',
    }));

    setFiles(prevFiles => [...prevFiles, ...limitedFiles]);
    await uploadFiles(limitedFiles);
  };


  const uploadFiles = async (files) => {
    for (const fileObj of files) {
      const formData = new FormData();
      formData.append('file', fileObj.file);

      try {
        const response = await IaApiClient.upload('assessment/upload', formData);
        setFiles(prevFiles =>
          prevFiles.map(f =>
            f.file === fileObj.file
              ? { ...f, status: 'uploaded', url: response.url, key: response.key }
              : f
          )
        );
      } catch (error) {
        setFiles(prevFiles =>
          prevFiles.map(f =>
            f.file === fileObj.file ? { ...f, status: 'error' } : f
          )
        );
        console.error('Error uploading file:', fileObj.file.name, error);
        notify('Error uploading file:', fileObj.file.name);
      }
    }
  };


  const deleteFile = async (keys) => {
    try {
      const response = await AssesmentRepository.deleteFiles(keys);
      if (response.success) {
        setFiles(prevFiles => prevFiles.filter(fileObj => !keys.includes(fileObj.key)));
      } else {
        console.error('Error deleting file:', response.data.error);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  const deleteAllFiles = async () => {
    const keys = files.map(fileObj => fileObj.key);
    if (keys?.length > 0) {
      await deleteFile(keys);
    }
  };

  const getDeployedProjects = async () => {
    try {
      const response = await ProjectRepository.getDeployedProjectDetails(keycloak.tokenParsed.preferred_username);
      setProjectData(response.data);
      // console.log(response.data);
      // setProjectData(projectDummyData);     
    }
    catch {
      console.error('Failed to fetch project data:',);
    }
  }

  const handleViewReports = (id, event) => {
    event.stopPropagation(); // Prevents click event from propagating to parent
    event.preventDefault();  // Prevents default action of the Link
    // Navigate to the reports page
    navigate(`/dashboard/${id}`);
  };

  const handleQueryableInsights = (id, event) => {
    event.stopPropagation(); // Prevents click event from propagating to parent
    event.preventDefault();  // Prevents default action of the Link
    // Implement your queryable insights logic here
    navigate(`/query/${id}`);
  };


  const handleSelect = (project) => {
    setSelectedProject(project.name);
    setSelectedProjectId(project.project_id);
    setQuestions(project.questions);
  };

  const createAssessmentDetails = async () => {
    const fileUrls = files.map(file => file.url);
    const body = {
      "project_id": selectedProjectId,
      "assessment_name": assessmentName,
      "description": description,
      "supported_document": fileUrls,
      "owner": keycloak.tokenParsed.preferred_username,
    }
    try {
      const response = await AssesmentRepository.createAssessment(body);
      const assesment_id = response.payload.data.id;
      navigate(`/assesmentPage/${assesment_id}`);
    }
    catch {
      console.error('Failed to create assesment',);
      notify('Failed to create assesment please try again later');
    }
  }


  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <CContainer style={{ gap: '2rem' }}>
        <CRow>
          <div className="createAssesment">
            <CButton onClick={() => { setCreateModal(true); getDeployedProjects(); }}>Create Assessment</CButton>
          </div>
        </CRow>
        <CRow className="assessment-card-row">
          {assessmentsCards?.data?.map((assessmentData, index) => (
            <Link to={`/assesmentPage/${assessmentData.id}`} key={index}  className="card-link col-sm-3 card-hover assessment-card-col mb-4">

              {/* <CCol sm="3" key={index} className="assessment-card-col mb-4"> */}
                <CCard className="assessment-card grid-item d-flex">
                  <CCardBody>
                    <CCardTitle className="title ellipsis">{assessmentData.assessment_name}</CCardTitle>
                    <GrStatusGoodSmall
                      className={`status-icon ${assessmentData.status === 'OPEN' ? 'status-open' : 'status-complete'}`}
                    />
                    <span
                      className={`status-text ${assessmentData.status === 'OPEN' ? 'status-open' : 'status-complete'}`}
                    >
                      {assessmentData.status}
                    </span>
                    <CCardText className="ellipsized-text mt-3 assessmentCardText  card-text-fixed-size">
                      {assessmentData.description}
                    </CCardText>
                    <div className="d-flex flex-column mb-3 mt-3">
                      <small className="text-medium-emphasis assessment-card-details">
                        Owner: {assessmentData.owner}
                      </small>
                      <small className="text-medium-emphasis assessment-card-details">
                        Created On: {new Date(assessmentData.createdAt).toISOString().split('T')[0]}
                      </small>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <CButton color="secondary" variant="outline" onClick={(event) => handleQueryableInsights(assessmentData.id, event)}>
                        Queryable insights
                      </CButton>
                      <CButton color="secondary" onClick={(event) => handleViewReports(assessmentData.id, event)}>
                        View reports
                      </CButton>
                    </div>
                  </CCardBody>
                </CCard>
              {/* </CCol> */}
            </Link>

          ))}
        </CRow>
      </CContainer>

      <CModal
        visible={createModal}
        alignment="center"
        onClose={() => { setCreateModal(false); setSelectedProject('Please select project'); setSelectedProjectId(''); deleteAllFiles() }}
        aria-labelledby="LiveDemoExampleLabel"
        backdrop="static"
        size="lg"
      >
        <CModalHeader>
          <CModalTitle>Create Assessment</CModalTitle>
        </CModalHeader>
        <CModalBody style={{ maxWidth: '70%', marginLeft: '2rem' }}>
          {projectData?.length === 0 ? (
            // <CSpinner />
            <b>No new deployed projects. You can create assessment only from deployed projects</b>
          ) : (
            <>
              <label className="form-label">Select Projects:</label><br />
              <CDropdown direction="center" style={{ width: '100%' }}>
                <CDropdownToggle color="secondary" className="flexGap5" style={{ justifyContent: 'space-between', border:'1px solid var(--active) !important' }}>
                  {selectedProject}
                  <span>▼</span>
                  {/* <CIcon icon={cilChevronBottom} style={{ cursor: 'pointer' }} /> */}
                </CDropdownToggle>
                <CDropdownMenu style={{ width: '100%' }}>
                  {projectData.map((project, index) => (
                    <CDropdownItem key={index} onClick={() => handleSelect(project)}>
                      {project.name}
                    </CDropdownItem>
                  ))}
                </CDropdownMenu>
              </CDropdown>
              <div className="mt-4">
                <div className="form-group" style={{ marginTop: "1rem" }}>
                  <label className="form-label">Assessment Name:</label>
                  <CFormInput
                    type="text"
                    placeholder="Eg: Client Feedback Survey"
                    value={assessmentName}
                    onChange={(e) => setAssessmentName(e.target.value)}
                  />
                </div>
                <div className="form-group" style={{ marginTop: "1rem" }}>
                  <label className="form-label">Description:</label>
                  <CFormTextarea
                    rows="5"
                    placeholder="Eg: Comprehensive assessment for client feedback for the second quarter"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="form-group flexGap1" style={{ marginTop: "1rem", width: '100%' }}>
                  {/* Supporting Documents Section */}
                  <label className="form-label">Supporting Documents:</label>
                  {/* Note Section */}
                  <div style={{ marginBottom: "1rem", color: 'var(--primary-light-bg)', fontSize: "0.9rem" }}>
                    <span>
                      Please download the template, fill it with the required information, and then upload the completed file below.
                      The supported file formats are <strong>.doc</strong>, <strong>.txt</strong>, and <strong>.pdf</strong>.
                      You can upload up to <strong>5 files</strong>, and each file must be <strong>10 MB</strong> or less.
                    </span>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <CButton href={asessmentTemplate} download="assessment_template.txt" color="info" className="custom-file-input" style={{ padding: '7px 12px' }}>
                      <CIcon icon={cilCloudDownload} customClassName="tempIcon" style={{ width: '1.7rem' }} />
                      Download Template
                    </CButton>
                    <label htmlFor="fileInput" className="custom-file-input labelBtn" style={{ padding: '7px 12px' }}>
                      <CIcon icon={cilCloudUpload} customClassName="tempIcon" style={{ width: '1.7rem' }} />
                      Choose File
                    </label>
                  </div>
                  <input type="file" accept=".doc,.txt,.pdf" id="fileInput" onChange={handleFileChange} style={{ display: 'none' }} />
                </div>
                <div className="form-group" style={{ marginTop: "1rem" }}>
                  {/* <label className="form-label">Upload Files:</label> */}
                  {/* <CFormInput
                    type="file"
                    multiple
                    accept=".doc,.txt,.pdf"
                    onChange={handleFileChange}
                  /> */}
                  <div>
                    <div style={{ maxHeight: '15rem', overflow: 'auto' }}>
                      {files && (
                        <ul style={{ paddingLeft: '0' }}>
                          {files.map((fileObj, index) => {
                            const fileExtension = fileObj.key.split('.').pop();
                            const icon = getFileIcon(fileExtension);
                            return (
                              <CListGroupItem
                                key={index}
                                style={{ display: 'flex', alignItems: 'center', padding: 10, width: '100%' }}
                                className="border-bottom mb-2"
                              >
                                {/* <CBadge color="secondary" shape="rounded-pill" style={{ marginRight: 10, minWidth: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  {index + 1}
                                </CBadge> */}
                                {/* <CIcon icon={icon} style={{ marginRight: 8 }} /> */}
                                <a href={fileObj.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: 5, flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                  {fileObj.file.name} ({fileObj.file.type})
                                </a>
                                {fileObj.status === 'pending' && <CSpinner style={{ marginLeft: '1rem' }} size="sm" />}
                                {fileObj.status === 'uploaded' && (
                                  <CTooltip placement="top" content="delete">
                                    <button className="delete-button" onClick={() => deleteFile(fileObj.key)}>
                                      <img style={{ height: '1rem', width: '1rem' }} src={trashIcon} alt="Delete" />
                                    </button>
                                  </CTooltip>
                                )}
                                {fileObj.status === 'error' && <span> Error uploading</span>}
                              </CListGroupItem>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => { setCreateModal(false); setSelectedProject('Please select project'); setSelectedProjectId(''); deleteAllFiles() }}
          >
            Close
          </CButton>
          {/* {selectedProject !== 'Please select project' && isValid && ( */}
          {!(selectedProject !== 'Please select project' && isValid) ? (
            <CTooltip placement="top" content="Fill all the details">
              <CButton
                color="success"
                className="disBut"
              >
                Create Assessment
              </CButton>
            </CTooltip>
          ) : (
            <CButton
              color="primary"
              onClick={() => { createAssessmentDetails(); }}
              disabled={false}
            >
              Create Assessment
            </CButton>
          )}

          {/* // )} */}
        </CModalFooter>
      </CModal>
    </>
  );

}

export default Assessment

