import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { CONFIG } from "src/config/envConstants";
import { serverErrors } from "./constants";
import { successHandler } from "./responseHandlers";
import keycloak from "src/Keycloak";

const getAxiosInstance = (baseUrl, client) => {
  let axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    headers: {
      "Trace-Id": uuidv4(),
      "Content-Type": "application/json",
      offset: -330
    },

  });
  axiosInstance.interceptors.request.use(async (config) => {
    const token = keycloak.token;
  
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.warn("No token available");
    }
  
    return config;
  }, (error) => {
    return Promise.reject(error);
  });


  return axiosInstance;
};

export const handleError = (error) => {
  console.log(`API error:  ${JSON.stringify(error)}`);
  let errorMessage = serverErrors.SERVER_ERROR;
  if (error?.message && error?.message === "Network Error") {
    console.log(`Network Error. Error: ${JSON.stringify(error)}`);
    //TODO Deal no network error
  }
  if (error?.response?.status >= 500) {
    errorMessage = serverErrors.SERVER_ERROR;
    //  errorMessage;
  }
  if (error?.response?.status >= 400 && error?.response?.status < 500) {
    errorMessage = error?.response?.data?.error || error?.response?.data?.message;
    //  errorMessage;
  }
  if (error?.response) {
    const errorMsg = error?.response?.data?.error || error?.response?.data?.message;
    if (errorMsg) {
      errorMessage = errorMsg;
    }
  } else {
    console.log(`Don't know the error. Error: ${JSON.stringify(error)}`);
  }
  return Promise.resolve(errorMessage);
};

class IaApiClient {
 client;
  constructor(baseUrl) {
    this.client = getAxiosInstance(baseUrl, this);
  }
async request(config) {
    try {
      // telemetry function here.
      // every api call here.

      // config check put or post call here.
      // and from here call telemetry

      let response = await this.client.request(config); //main api call.
      let handledResponse = await successHandler(response);
      return Promise.resolve(handledResponse);
    } catch (error) {
      let handledError = await handleError(error);
      return Promise.reject(handledError);
    }
  }
   async get(url, params) {
    return this.request({ method: "GET", url, params });
  }
   async put(url, data, params) {
    return this.request({ method: "PUT", url, data, params });
  }
   async post(url, data, params) {
    return this.request({ method: "POST", url, data, params });
  }
   async delete(url, data, params) {
    return this.request({ method: "DELETE", url, data, params });
  }
   async patch(url, data, params) {
    return this.request({ method: "PATCH", url, data, params });
  }
  async upload(url, formData) {
    try {
      let response = await this.client.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return Promise.resolve(response.data);
    } catch (error) {
      let handledError = await handleError(error);
      return Promise.reject(handledError);
    }
  }
}

const baseURL = CONFIG.REACT_APP_IA_DATA_COLLECTOR_API_BASE_URL
const iaApiClient = new IaApiClient(baseURL);
export { iaApiClient as IaApiClient };
