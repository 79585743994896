import { responseDetailsEndPoints } from "./endPoints";
import { toast } from "react-toastify";
import { IaApiClient } from "src/network/iaApiClient";

 class ResponsesRepository{

    getResponseDetails = async (projectId, questionId)=> {
        let data = await IaApiClient.get(responseDetailsEndPoints.getResponseDetals(projectId, questionId)).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while retreiving filteres assessments");
        })
        return data;
    }
}

const responsesRepository = new ResponsesRepository();
export { responsesRepository as ResponsesRepository };