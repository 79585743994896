import React, { useState, useRef } from 'react';
import { CCard, CCardBody, CButton, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CTooltip, CCardHeader, CBadge, CFormCheck, CSpinner } from '@coreui/react';
import * as XLSX from 'xlsx';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { ProjectRepository } from 'src/repositories/projectRepository';
import { UserRepository } from 'src/repositories/userRepository';
import CIcon from '@coreui/icons-react'
import { cilSave } from '@coreui/icons';
import _ from 'lodash';
import { max_questions, max_users } from './exportData';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import userTemplate from '../../assets/temp/random_contacts.xlsx';
import questionsTemplate from '../../assets/temp/questions_template.xlsx';
import { cilCloudDownload, cilCloudUpload, cilListLowPriority, cilDataTransferDown } from '@coreui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { languageOptions } from './exportData';
import { Step1Static } from './Step1Static';
import messages from '../../assets/images/messages-1-svgrepo-com.svg';
import questionsIcon from '../../assets/images/question.png';
import languageIcon from '../../assets/images/language-svgrepo-com.svg';
var cleanedProjectId;

const viewDataComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { projectId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [desc, setDesc] = useState([]);
    const [initialDesc, setinitialDesc] = useState([]);
    const [name, setName] = useState([]);
    const [step, setStep] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [startMessage, setStartMessage] = useState([]);
    const [endMessage, setEndMessage] = useState([]);
    const [initialStartMessage, setInitialStartMessage] = useState(null);
    const [initialEndMessage, setInitialEndMessage] = useState(null);
    const [initialQuestionsData, setInitialQuestionsData] = useState([]);
    const [initialContactsData, setInitialContactsData] = useState([]); // Add a new state for contacts [Step 2
    const [contacts, setContacts] = useState([]); // Add a new state for contacts
    const [file, setFile] = useState(null);
    const [contactsFile, setContactsFile] = useState(null); // Add a new state for the file [Step 2
    const [error, setError] = useState(null);
    const [changesPresent, setchangesPresent] = useState(false);
    const [isEditingDesc, setIsEditingDesc] = useState(false);
    const [editableDesc, setEditableDesc] = useState(desc);
    const [selectedLanguages, setSelectedLanguages] = useState([{ value: 'english', label: 'English' }]);
    const [initialLanguages, setInitialLanguages] = useState([{ value: 'english', label: 'English' }]);
    const [userFiles, setuserFiles] = useState([]);
    const spanRef = useRef();
    const textAreaRef = useRef();
    const [isPageLoading, setIsPageLoading] = useState(true);
    useEffect(() => {
        const cleanId = cleanProjectId(projectId);
        cleanedProjectId = cleanId;
        const fetchData = async () => {
            try {
                const res = await ProjectRepository.getProjectDetailsById(cleanedProjectId);
                const contactsRes = await UserRepository.getUserDetails(cleanedProjectId);
                const data = res.data[0];
                if (data) {
                    setIsPageLoading(false);
                    var contactsData;
                    if (contactsRes && contactsRes.data) {
                        contactsData = contactsRes.data.map(contact => ({
                            ...contact,
                            phone_no: parseInt(contact.phone_no, 10), // Convert the phone_no property to an integer
                            isFieldOfficer: contact.is_field_officer ? "yes" : "no"
                        }));
                    }
                    if (contactsData) {
                        setContacts(contactsData); // Set the contacts state with converted phone numbers
                        setInitialContactsData(contactsData);
                    }
                    if (data.questions) {
                        setQuestions(data.questions); // Set the questions state
                        setInitialQuestionsData(data.questions); // Set the initial questions data
                    }
                    if (data.name) {
                        setName(data.name);
                    }
                    if (data.description) {
                        setDesc(data.description);
                        setinitialDesc(data.description);
                    }
                    if (data.languages) {
                        const transformedLanguages = transformLanguages(data.languages);
                        setSelectedLanguages(transformedLanguages);
                        setInitialLanguages(transformedLanguages);
                    }
                    if (data.template_messages) {
                        const firstMessage = data.template_messages.find(msg => msg.message_type === 'first');
                        const lastMessage = data.template_messages.find(msg => msg.message_type === 'last');

                        if (firstMessage) {
                            setStartMessage(firstMessage.message_text);
                            setInitialStartMessage(firstMessage.message_text);
                        }

                        if (lastMessage) {
                            setEndMessage(lastMessage.message_text);
                            setInitialEndMessage(lastMessage.message_text);
                        }
                    }
                    // set your state based on the fetched data
                }
            } catch (error) {
                console.error('Failed to fetch project details:', error);
                // notify('Failed to fetch project details');
            }
        };

        if (cleanedProjectId) {
            fetchData();
        } else {
            navigate('/projects');
        }
        setIsLoading(false);
    }, [projectId]);

    function transformLanguages(languages) {
        return languages.map(language => ({
            value: language?.toLowerCase(),
            label: language.charAt(0).toUpperCase() + language.slice(1) // Capitalize the first letter
        }));
    }

    const calculateTextWidth = () => {
        return `${spanRef.current.offsetWidth + 10}px`; // +10 for some padding
    };

    useEffect(() => {
        if (spanRef.current && textAreaRef.current) {
            // textAreaRef.current.style.width = calculateTextWidth(); // Set width
            // Adjust height dynamically based on the scrollHeight of the textarea
            textAreaRef.current.style.height = 'auto';
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
    }, [desc]);

    const cleanProjectId = (id) => {
        if (!id.includes('&')) {
            return id;
        }
        const parts = id.split('&');
        return parts[0]; // Return only the part before the first '&'
    };

    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);
    const notify = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyInfo = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const handleDownload = (data) => {
        const ws = XLSX.utils.json_to_sheet(data.map(item => ({
            'Name': item.name,
            'Phone Number': item.phone_no,
            'Is Field Officer': item.is_field_officer ? 'Yes' : 'No',
            'Project ID': item.project_id
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Contacts");

        // Create file name with current date
        const date = new Date();
        const fileName = `Contacts_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.xlsx`;

        // Write the Excel file
        XLSX.writeFile(wb, fileName);
    };
    const stepChanges = async (prevStep, stepnum) => {
        setStep(stepnum);
    }
    if (isLoading) {
        return <div className='spinner'>Loading...</div>; // Show loading state until questions are ready
    }
    return (
        <>
            {isPageLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <CSpinner className='spinner' />
                </div>
            ) : (<div>
                <CCard className='ccard'>
                    <CCardHeader className='view-data-header'>
                        <div >
                            {name}
                        </div>
                    </CCardHeader>
                    <CCardBody>
                        <div>
                            <ToastContainer />
                        </div>
                        {/* <div style={{ fontSize: '20px' }}>
                        {desc}
                    </div> */}
                        <div className='flexStart' style={{ fontSize: '20px' }}>
                            <textarea
                                ref={textAreaRef}
                                value={desc}
                                onChange={(e) => setDesc(e.target.value)}
                                className='descInput'
                                placeholder="Enter description here..."
                                style={{ minWidth: '20%', width: '90%', marginBottom: '2rem', maxHeight: '10rem', resize: 'none' }}
                                disabled={true}
                            />
                            {/* Hidden span to measure text width */}
                            <span ref={spanRef} style={{ position: 'absolute', visibility: 'hidden', height: 'auto', width: 'auto', whiteSpace: 'nowrap', fontSize: '20px' }}>
                                {desc}
                            </span>
                            {desc !== initialDesc && (
                                <CIcon icon={cilSave} size="xl" style={{ cursor: "pointer" }} />
                            )}
                        </div>
                        {/* {error && <p className="error-message">{error}</p>} */}
                        <div className="container">
                            <ul className="progressbar">
                                <li onClick={() => { setStep(0) }} className={`card-header-step step ${step >= 0 ? 'active' : ''}`}>
                                    <div className='mainNavDiv'>
                                        <img style={{ width: '2rem' }} src={messages} />
                                        {/* <div className='numDiv'>
                                        📧
                                    </div> */}
                                        <div>
                                            Configure Messages
                                        </div>
                                    </div>
                                </li>
                                <li onClick={() => { setStep(1) }} className={`card-header-step step ${step >= 1 ? 'active' : ''}`}>
                                    <div className='mainNavDiv'>
                                        <img style={{ width: '2rem' }} src={questionsIcon} />
                                        {/* <div className='numDiv'>
                                        📝
                                    </div> */}
                                        <div>
                                            Upload Questions
                                        </div>
                                    </div>
                                </li>
                                {/* <li onClick={() => { setStep(2) }} className={`card-header-step step ${step >= 2 ? 'active' : ''}`}>
                                <div className='mainNavDiv'>
                                    <div className='numDiv'>
                                        👤
                                    </div>
                                    <div>
                                        Upload Users
                                    </div>
                                </div>
                            </li> */}
                                <li onClick={() => { setStep(3) }} className={`card-header-step step ${step >= 3 ? 'active' : ''}`}>
                                    <div className='mainNavDiv'>
                                        <img style={{ width: '2rem' }} src={languageIcon} />
                                        {/* <div className='numDiv'>
                                        🌐
                                    </div> */}
                                        <div>
                                            Select Language
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </CCardBody>
                </CCard>
                {step === 0 && <>
                    <CCard className="custom-card ccard">
                        <CCardHeader className='greeting-sec-header'>
                            Your Greeting and Closure Messages
                        </CCardHeader>
                        <CCardBody>
                            <div className="mb-3">
                                <h6 className='textcolor'>Greeting Message:</h6>
                                <textarea value={startMessage} className="form-control text-area-style" rows="2" disabled={true}></textarea>
                            </div>
                            <div className="mb-3">
                                <h6 className='textcolor'>Closure Message:</h6>
                                <textarea value={endMessage} className="form-control text-area-style" rows="2" disabled={true}></textarea>
                            </div>
                        </CCardBody>
                    </CCard>
                    <CButton onClick={() => { setStep(1) }} style={{ marginTop: '1rem' }} className="custom-file-input">
                        Next
                    </CButton>
                </>}
                {step === 1 && <Step1Static nextStep={nextStep} questions={questions} file={file} projectId={cleanedProjectId} stepChanges={stepChanges} noteVisible={false} />}
                {/* {step === 2 && <Step2 nextStep={nextStep} prevStep={prevStep} contacts={contacts} contactsFile={contactsFile} projectId={cleanedProjectId} stepChanges={stepChanges} userFiles={userFiles} handleDownload={handleDownload} />} */}
                {step === 3 && <Step3 selectedLanguages={selectedLanguages} setSelectedLanguages={setSelectedLanguages} prevStep={prevStep} projectId={cleanedProjectId} questions={questions} stepChanges={stepChanges} initialLanguages={initialLanguages} />}
            </div >)}
        </>
    );
};


const Step2 = ({ nextStep, prevStep, onFileChange, contacts, contactsFile, onDeleteContact, projectId, stepChanges, userFiles, removeContactsById, handleDownload }) => {
    const localId = projectId;
    const [initialContactsData, setInitialContactsData] = useState([]);

    useEffect(() => {
        // Save the initial contacts data when component mounts
    }, []);

    if (!contacts) {
        contacts = [];
    }

    const handleClickNext = () => {
        stepChanges(2, 3);
    };

    return (
        <div>
            {contacts?.length == 0 ? (
                <>
                    {/* <div className="empty-placeholder"> */}
                    {/* <label htmlFor="fileInputContacts"> */}
                    {/* <label>
                            <img src={emptyPlaceholder} alt="Empty placeholder" />
                        </label> */}
                    {/* <span>Please add a file</span> */}
                    {/* </div> */}
                </>
            ) : (
                <CCard style={{ marginTop: '1rem', marginBottom: '1rem' }} className='ccard'>
                    <CCardBody>
                        <div>

                            <div className='flexSpace'>
                                <div className='flexStart'>
                                    <h3>Total number of contacts</h3>
                                    <span className="slNumbers">{contacts?.length}</span>
                                </div>
                                <CButton onClick={() => handleDownload(contacts)} color="secondary" className="custom-file-input">
                                    <CIcon icon={cilDataTransferDown} customClassName="tempIcon" />
                                    Download User List
                                </CButton>
                            </div>
                            <div className='flexStart'>
                                <h3>Number of Field officers</h3>
                                <span className="slNumbers">{contacts.filter(contact => contact.isFieldOfficer === 'yes')?.length}</span>
                            </div>
                            <ul className="questions-list">
                                {userFiles.map((file, index) => (
                                    <li key={file.id} className="file-item">
                                        <div className="nameDiv">
                                            <span className="slNumbers">{`${index + 1}`}</span>
                                            <span className="nameTag">{file.name}</span>
                                            <span className="nameTag userTag">{file.contactsCount} contacts</span>
                                        </div>
                                        <button className="delete-button" onClick={() => removeContactsById(file.id)}>
                                            <img style={{ height: '1.5rem', width: '1.5rem' }} src={trashIcon} alt="Delete" />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </CCardBody>
                </CCard>
            )}
            <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }} >
                <CButton color="secondary" onClick={prevStep}>Previous</CButton>
                <CButton color="primary" onClick={handleClickNext}>Next</CButton>
            </div>
        </div>
    );
};

Step2.propTypes = {
    nextStep: PropTypes.func,
    prevStep: PropTypes.func,
    onFileChange: PropTypes.func,
    contacts: PropTypes.array,
    contactsFile: PropTypes.object,
    onDeleteContact: PropTypes.func,
    projectId: PropTypes.string,
    stepChanges: PropTypes.func,
    userFiles: PropTypes.array,
    removeContactsById: PropTypes.func,
    contacts: PropTypes.array,
    handleDownload: PropTypes.func,

};


const Step3 = ({ prevStep, selectedLanguages, setSelectedLanguages, projectId, questions, stepChanges, initialLanguages }) => {
    const localId = projectId;
    const navigate = useNavigate();
    // const [selectedLanguages, setSelectedLanguages] = useState([])

    const handleClickNext = () => {
        navigate('/projects');
    }

    const handleClickPrev = () => {
        stepChanges(3, 1);
    }

    // Handle the change event for the dropdown
    const handleLanguageChange = (selectedOptions) => {
        const englishOption = { value: 'english', label: 'English' };
        const selectedValues = selectedOptions.map(option => option.value);
        if (!selectedValues.includes('english')) {
            selectedOptions = [englishOption, ...selectedOptions];
        }
        setSelectedLanguages(selectedOptions);
        //add this method for next button=============
        // try {

        //     ProjectRepository.updateProject({ languages: selectedOptions },projectId)
        //     .then(() => {
        //         // Proceed to the next step after updating the project
        //         nextStep();
        //     })
        //     .catch(error => {
        //         // Handle error
        //         console.error('Failed to update project:', error);
        //         // You can also set an error message state here if needed
        //     });

        //     // Call backend API to update languages column
        // } catch (error) {
        //     console.error('Failed to update languages:', error);
        //     // Handle error
        // }
    };
    return (
        <div>
            <CCard style={{ marginTop: '1rem', marginBottom: '1rem' }} className='ccard'>
                <CCardBody>
                    <h5 style={{color: 'white'}}>Selected languages</h5>
                    {/* <Select
                        isMulti
                        options={languageOptions}
                        value={selectedLanguages}
                        onChange={handleLanguageChange}
                        isDisabled={true}
                    /> */}
                    <div className="selected-languages-chips">
                        {selectedLanguages.map(lang => (
                            <CBadge key={lang.value} color="primary" style={{ margin: '0.25rem' }}>
                                {lang.label}
                            </CBadge>
                        ))}
                    </div>
                    <div className="language-checkboxes">
                        <div style={{ margin: '0.5rem 0' }}>
                            <CFormCheck
                                id="english"
                                name="English"
                                value="english"
                                label="English"
                                checked={true}
                                disabled={true}
                            />
                        </div>
                        {languageOptions
                            .filter(option => option.value !== 'english') // Filter out English as it's already handled
                            .map(option => (
                                <div key={option.value} style={{ margin: '0.5rem 0' }}>
                                    <CFormCheck
                                        id={option.value}
                                        name={option.label}
                                        value={option.value}
                                        label={option.label}
                                        checked={selectedLanguages.some(lang => lang.value === option.value)}
                                        disabled={true}
                                    />
                                </div>
                            ))}
                    </div>
                </CCardBody>
            </CCard>
            {/* <CButton style={{ margintop: '1rem;' }} color="secondary" onClick={prevStep}>Previous</CButton>
            <CButton style={{ margintop: '1rem;' }} color="secondary" onClick={prevStep}>Previous</CButton> */}
            <div style={{ marginTop: '1rem', display: 'flex', gap: '1rem' }} >
                <CButton color="secondary" onClick={handleClickPrev}>Previous</CButton>
                <CButton color="primary" onClick={handleClickNext}>Go Home</CButton>
            </div>
        </div>
    );
};

Step3.propTypes = {
    prevStep: PropTypes.func,
    selectedLanguages: PropTypes.array,
    setSelectedLanguages: PropTypes.func,
    projectId: PropTypes.string,
    questions: PropTypes.array,
    initialLanguages: PropTypes.array,
};

export default viewDataComponent;