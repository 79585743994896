import { userDetailsEndPoints } from "./endPoints";
import { toast } from "react-toastify";
import { IaApiClient } from "src/network/iaApiClient";

 class UserRepository{
    createUsers = async (body,projectId)=> {
        // sample body
        // {
        //     "user" : [{
        //         "name" : "shashank",
        //         "phone_no" : "9566544510"
        //     },
        //     {
        //         "name" : "gayathri",
        //         "phone_no" : "9566544520"
        //     }]
        // }

        let data = await IaApiClient.post(userDetailsEndPoints.createUsers(projectId), body).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while creating user");
        })
        return data;
    }

    getUserDetails = async (projectId) => {
        let data = await IaApiClient.get(userDetailsEndPoints.getUserDetails(projectId)).catch((err)=>{
            console.log("Error", err);
            toast.error("Something went wrong while fetching user details");
        })
        return data;
    }
}

const userRepository = new UserRepository();
export { userRepository as UserRepository };