import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProjectRepository } from 'src/repositories/projectRepository';
import DescriptiveQuestionStats from '../assesment/Descriptive';
import MultipleChoiceQuestionStats from '../assesment/MultipleCoice';
import LocationQuestionStats from '../assesment/location';
import { CContainer, CRow, CCol, CCardBody, CCard, CButton, CForm, CFormLabel, CFormInput, CFormCheck, CAlert } from '@coreui/react';

const QSummary = ({ projectId }) => {
  // const [questionsData, setQuestionsData] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [response, setResponse] = useState([]);

  useEffect(() => {
    if (projectId) {
      fetchQuestionSummary();
    }
  }, [projectId]);

  const fetchQuestionSummary = async () => {
    try {
      const response = await ProjectRepository.getQuestionsSummary(projectId);
      setAssessments(response.qData);
      setResponse(response);
    } catch (error) {
      console.error('Failed to fetch questions data', error);
    }
  };

  if (!assessments) {
    return <div className='textcolor'>Loading...</div>;
  }


  return (
    <CContainer>
      <h5>Submissions: {response?.totalUsers}</h5>
      <CCard className='ccard'>
        <CCardBody>
          {assessments?.length > 0 ? (
            <CCol className="dataCol">
              {assessments.map((assessment, index) => (
                <React.Fragment key={index}>
                  {assessment.q_type === 'Descriptive' && (
                    <DescriptiveQuestionStats {...assessment} projectId={projectId} />
                  )}
                  {assessment.q_type === 'Multiple Choice' && (
                    <MultipleChoiceQuestionStats {...assessment} />
                  )}
                  {assessment.q_type === 'Location' && (
                    <LocationQuestionStats {...assessment} projectId={projectId}/>
                  )}
                </React.Fragment>
              ))}
            </CCol>
          ) : (
            <CAlert color="warning">No responses to display</CAlert>
          )}
        </CCardBody>
      </CCard>
    </CContainer>
  );
};

QSummary.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default QSummary;
