import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    CRow, CCol, CCardBody, CCard, CButton, CFormInput, CCardHeader, CNav, CNavItem, CNavLink,
    CDropdown,
    CDropdownToggle,
    CDropdownItem,
    CDropdownMenu,
    CBadge,
    CTooltip,
    CSpinner,
    CFormTextarea
} from '@coreui/react';
import { ToastContainer, toast } from 'react-toastify';
import CIcon from '@coreui/icons-react'
import { cibAdobe, cibMicrosoft, cilFile, cilCloudUpload } from '@coreui/icons';
import { CListGroup, CListGroupItem } from '@coreui/react';
import { useParams } from 'react-router-dom';
import { AssesmentRepository } from "src/repositories/assessmentRepository";
import Responses from './Responses';
import Curated from './Curated';
import Discarded from "./Discarded";
import { IaApiClient } from "src/network/iaApiClient";
import trashIcon from '../../assets/images/trash-can-regular-red.svg';
import { CONFIG } from "src/config/envConstants";
var cleanedAssessmentId;

const assesmentPage = () => {

    const [questions, setQuestions] = useState([]);
    const [assessmentName, setAssessmentName] = useState('');
    const [activeTab, setActiveTab] = useState('details');
    const { assessmentId } = useParams();
    const [projectId, setProjectId] = useState('');
    const [initialDesc, setInitialDesc] = useState('');
    const [editedDesc, setEditedDesc] = useState('');
    const [initialFiles, setInitialFiles] = useState([]);
    const [editedFiles, setEditedFiles] = useState([]);
    const [tempFiles, setTempFiles] = useState([]);
    const [isPageLoading, setIsPageLoading] = useState(true);

    useEffect(() => {
        const cleanId = cleanProjectId(assessmentId);
        cleanedAssessmentId = cleanId;
        getassessmentDetailsById();
    }, [assessmentId]);

    const cleanProjectId = (id) => {
        if (!id.includes('&')) {
            return id;
        }
        const parts = id.split('&');
        return parts[0]; // Return only the part before the first '&'
    };

    const getassessmentDetailsById = async () => {
        try {
            const assesmentDetails = await AssesmentRepository.getAssessmentDetailsById(cleanedAssessmentId);
            const data = assesmentDetails.data[0];
            setIsPageLoading(false);
            setQuestions(data.questions);
            setAssessmentName(data.assessment_name);
            setInitialDesc(data.description);
            setEditedDesc(data.description);
            setInitialFiles(data.supported_document.map(url => ({ url, key: url.split('/').pop().split('?')[0] })));
            setEditedFiles(data.supported_document.map(url => ({ url, key: url.split('/').pop().split('?')[0] })));
            setProjectId(data.project_id);
        }
        catch (error) {
            console.error('Error', error);
        }
    }

    const truncate = (text, limit) => {
        return text?.length > limit ? text.substring(0, limit) + '...' : text;
    };

    const notify = (message) => toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    const notifyInfo = (message) => toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const getFileIcon = (fileExtension) => {
        switch (fileExtension?.toLowerCase()) {
            case 'pdf':
                return cibAdobe;
            case 'doc':
            case 'docx':
                return cibMicrosoft;
            case 'txt':
                return cilFile;
            default:
                return cilFile;
        }
    };

    const handleDescChange = (e) => {
        setEditedDesc(e.target.value);
    };

    // const handleSaveDesc = async () => {
    //     const body = {
    //         description: editedDesc
    //     }
    //     try {
    //         const res = await AssesmentRepository.updateAssessmentDetails(cleanedAssessmentId, body);
    //         console.log(res);
    //         setAssessmentDesc(editedDesc);
    //     } catch {
    //         console.error('Failed to update description');
    //     }
    // };

    const handleFileChange = async (e) => {
        const maxFiles = 3; // Maximum number of files allowed
        const selectedFiles = Array.from(e.target.files).filter(file =>
            ['application/msword', 'text/plain', 'application/pdf'].includes(file.type)
        ).map(file => ({
            file,
            status: 'pending',
            url: '',
            key: '',
        }));

        const totalFiles = editedFiles?.length + selectedFiles?.length;

        if (totalFiles > maxFiles) {
            notify(`You can only upload up to ${maxFiles} files.`);
            console.error(`You can only upload up to ${maxFiles} files.`);
            return;
        }

        const limitedFiles = selectedFiles.slice(0, maxFiles - editedFiles?.length);
        const uploadedFiles = await uploadFiles(limitedFiles);
        setEditedFiles(prevFiles => [...prevFiles, ...uploadedFiles]);
        // await updateAssessmentFiles([...files, ...uploadedFiles].map(f => f.url));
    };

    const uploadFiles = async (files) => {
        const uploadedFiles = [];
        for (const fileObj of files) {
            const formData = new FormData();
            formData.append('file', fileObj.file);
            try {
                const response = await IaApiClient.upload('assessment/upload', formData);
                uploadedFiles.push({ ...fileObj, status: 'uploaded', url: response.data.url, key: response.data.key });
            } catch (error) {
                console.error('Error uploading file:', fileObj.file.name, error);
            }
        }
        return uploadedFiles;
    };

    const deleteFileTemp = async (key) => {

        const updatedFiles = editedFiles.filter(fileObj => fileObj.key !== key);
        setEditedFiles(updatedFiles);
        setTempFiles(updatedFiles);
    };

    const deleteFile = async (key) => {
        try {
            const response = await AssesmentRepository.deleteFiles(key);
            const updatedFiles = editedFiles.filter(fileObj => fileObj.key !== key);
            setEditedFiles(updatedFiles);
            // await updateAssessmentFiles(updatedFiles.map(f => f.url));
        } catch (error) {
            console.error('Error deleting file:', error);
        }
    };

    const handleSave = async () => {
        const updatedFiles = editedFiles.map(f => f.url);
        console.log(updatedFiles);
        const body = {
            description: editedDesc,
            files: updatedFiles
        }
        if (tempFiles?.length > 0) {
            for (const file of tempFiles) {
                deleteFile(file.key);
            }
        }
        try {
            const res = await AssesmentRepository.updateAssessmentDetails(cleanedAssessmentId, body);
            console.log(res);
            setInitialDesc(editedDesc);
            setInitialFiles(editedFiles);
        } catch {
            console.error('Failed to update assessment details');
        }
    };


    const hasChanges = editedFiles?.length > 0 && (
        initialDesc !== editedDesc ||
        initialFiles?.length !== editedFiles?.length ||
        initialFiles.some((file, index) => file.key !== editedFiles[index]?.key)
    );

    const renderContent = () => {
        switch (activeTab) {
            case 'details':
                return <div>
                    <div style={{ width: "90%", marginBottom: "3rem" }}>
                        <h5>Description:</h5>
                        <p>{editedDesc}</p>
                        {/* {
                            <>
                                <CFormTextarea
                                    rows="5"
                                    value={editedDesc}
                                    onChange={handleDescChange}
                                />
                            </>
                        } */}
                    </div>
                    <div style={{ width: "50%", marginTop: "1rem" }}>
                        <h5 >Supporting Documents:</h5>
                        <CListGroup>
                            {editedFiles.map((file, index) => {
                                const fileExtension = file.key.split('.').pop();
                                return (
                                    <CListGroupItem
                                        key={index}
                                        style={{ display: 'flex', alignItems: 'center', padding: 10, width: '30rem',     border: '1px solid var(--primary-light-bg)' }}
                                        className="mb-2"
                                    >
                                        {/* <CBadge color="secondary" shape="rounded-pill" style={{ marginRight: 10, minWidth: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {index + 1}
                                        </CBadge> */}
                                        {/* <CIcon icon={icon} style={{ marginRight: 8, color: '#6c757d' }} /> */}
                                        <a href={file.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: 5, flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            {file.key}
                                        </a>
                                        <span style={{ color: '#6c757d', flexShrink: 0 }}>({fileExtension})</span>
                                        {/* <CTooltip placement="top" content="delete">
                                            <button className="delete-button" onClick={() => deleteFileTemp(file.key)}>
                                                <img style={{ height: '1rem', width: '1rem' }} src={trashIcon} alt="Delete" />
                                            </button>
                                        </CTooltip> */}
                                    </CListGroupItem>
                                );
                            })}
                        </CListGroup>

                        {/* <label htmlFor="fileInput" className="custom-file-input labelBtn" style={{ padding: '7px 12px', width: '10rem', margin: '1rem 0 2rem 0' }}>
                            <CIcon icon={cilCloudUpload} customClassName="tempIcon" style={{ width: '1.7rem' }} />
                            Choose File
                        </label>
                        <input type="file" accept=".doc,.txt,.pdf" id="fileInput" onChange={handleFileChange} style={{ display: 'none' }} /> */}

                        {/* <CButton color="success" onClick={handleSave} disabled={!hasChanges} className="mt-1 me-2">Save</CButton> */}
                    </div>
                </div>;
            case 'responses':
                return <Responses projectId={projectId} assessmentId={cleanedAssessmentId}></Responses>;
            case 'curatedResponses':
                return <Curated projectId={projectId} assessmentId={cleanedAssessmentId}></Curated>;
            case 'discarded':
                return <Discarded projectId={projectId} assessmentId={cleanedAssessmentId}></Discarded>;
            default:
                return null; // Return null if no tab is selected
        }
    };

    return (
        <>
            {isPageLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <CSpinner className='spinner' />
                </div>
            ) : (
                <>
                    <div>
                        <ToastContainer />
                    </div>
                    <CCard className="assessment-verify-card">
                        <CCardHeader className="title ellipsis">
                            {assessmentName}
                        </CCardHeader>
                        <CCardBody>
                            <h5 >Questions:</h5>
                            <CListGroup>
                                {questions && questions.map((question) => (
                                    <CListGroupItem key={question.q_id} className="d-flex align-items-center flexgap1 questions-background">
                                        <div>
                                            <strong>Q{question.sequence_no}.</strong> {question.q_text}
                                        </div>
                                        {question.q_type === "Multiple Choice" && (
                                            <CDropdown direction="center">
                                                <CDropdownToggle size="sm" color="secondary">
                                                    Multiple Choice
                                                    <span style={{marginLeft: "3px", color: 'var(--primary)'}}>▼</span>
                                                </CDropdownToggle>
                                                <CDropdownMenu>
                                                    {question.options && question.options.map((option, index) => (
                                                        <CDropdownItem key={index}>
                                                            {option}
                                                        </CDropdownItem>
                                                    ))}
                                                </CDropdownMenu>
                                            </CDropdown>
                                        )}
                                    </CListGroupItem>
                                ))}
                            </CListGroup>
                        </CCardBody>
                    </CCard>

                    <CCard className='assessment-verify-card'style={{ marginTop: "1rem", minHeight: "30rem" }}>
                        <CCardBody>
                            <CNav variant="tabs" className="sub-tabs">
                                <CNavItem>
                                    <CNavLink
                                        active={activeTab === 'details'}
                                        onClick={() => setActiveTab('details')}
                                        className={`pointer ${activeTab === 'details' ? 'active-tab' : ''}`}
                                    >
                                        Details
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink
                                        active={activeTab === 'responses'}
                                        onClick={() => setActiveTab('responses')}
                                        className={`pointer ${activeTab === 'responses' ? 'active-tab' : ''}`}
                                    >
                                        Unverified
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink
                                        active={activeTab === 'curatedResponses'}
                                        onClick={() => setActiveTab('curatedResponses')}
                                        className={`pointer ${activeTab === 'curatedResponses' ? 'active-tab' : ''}`}
                                    >
                                        Verified
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink
                                        active={activeTab === 'discarded'}
                                        onClick={() => setActiveTab('discarded')}
                                        className={`pointer ${activeTab === 'discarded' ? 'active-tab' : ''}`}
                                    >
                                        Rejected
                                    </CNavLink>
                                </CNavItem>
                            </CNav>
                            <CRow className="mt-4">
                                {activeTab ? (
                                    <CCol>
                                        {renderContent()}
                                    </CCol>
                                ) : null}
                            </CRow>
                        </CCardBody>
                    </CCard></>
            )}
        </>
    );
};

export default assesmentPage;
